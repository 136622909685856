@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.sources-destinations {
  .header {
    min-height: 0;
    padding: 25px;
  }

  .icon {
    width: 35px;
    box-sizing: border-box;
    padding-right: 0;
    padding-left: 0;
    img {
      width: 35px;
      margin-top: 3px;
    }
  }
}
