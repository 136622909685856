@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.container {
  position: relative;
  box-sizing: border-box;
}

.inputWrapper {
  position: relative;
  height: 34px;
  display: flex;
  background-color: white;
  box-sizing: border-box;
  padding: 5px 8px;
  gap: 5px;
  border-radius: $br_small;
  border: $border_grey_light_thin;
  transition: $transition_default;
  align-items: center;
  cursor: pointer;

  &.sm {
    height: 24px;

    .sourceLogoWrapper {
      display: none;
    }

    .input,
    .ghost {
      font-size: 13px;
    }
  }

  &.lg {
    height: 44px;

    .caretWrapper {
      padding: 8px;
    }
  }

  &.error {
    border-color: $color_warning_red;
    animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  &.readOnly {
    background-color: $color_catskill-white;
    cursor: not-allowed;
    color: $color_grey;

    .input {
      cursor: not-allowed;

      &::placeholder {
        color: $color_grey_lighter;
      }
    }
  }

  &.open {
    border-color: $color_primary;
  }

  &:hover:not(.error):not(.readOnly):not(.open) {
    border-color: $color_grey_lighter;
  }

  .sourceLogoWrapper {
    height: 24px;
    width: 24px;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: $color_primary;
    transition: all 0.1s ease;

    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
      }
    }

    .sourceLogo {
      height: 100%;
    }
  }

  .shrunk {
    width: 0;
    padding: 0;
  }

  .input {
    border: none;
    outline: none;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;

    &.hasValue:not(:focus)::placeholder {
      opacity: 1;
    }
  }

  .ghost {
    height: 0;
    position: absolute;
    word-wrap: none;
    overflow-y: hidden;
  }

  .input,
  .ghost {
    font-size: 15px;
  }

  .closeButton {
    margin-right: -5px;
  }

  .caretWrapper {
    border-left: $border_grey_light_thin;
    color: $color_grey_lighter;
    font-size: 11px;
    padding: 4px 8px;
    margin-right: -6px;
  }
}

.dropdown {
  background-color: white;
  box-shadow: $bs_small;
  border: $border_grey_light_thin;
  border-radius: $br_small;
  box-sizing: border-box;

  &.embedded {
    width: 100%;
  }

  &:not(.embedded) {
    z-index: 11;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    min-width: 100%;

    &.rightAlign {
      left: unset;
      right: 0;
    }
  }

  &.wide {
    width: 640px;
  }

  &:not(.wide) {
    width: 320px;
  }

  .header {
    border-bottom: $border_grey_light_thin;
    border-top-left-radius: $br_small;
    border-top-right-radius: $br_small;
    padding: 0 15px;
    height: 44px;
    background-color: $color_catskill-white;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;

    .filterBy {
      flex: 1;
    }
  }

  .categoryHeader {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }

  .content {
    padding: 10px;
    max-height: 400px;
    overflow: auto;
  }

  .groups {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .group {
    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .groupedListWrapper {
      padding: 5px 10px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .columns {
    display: flex;
    gap: 10px;

    .separator {
      border-right: $border_grey_light_thin;
    }
  }

  .list {
    flex: 1;
  }

  .row {
    cursor: pointer;
    padding: 5px;
    font-size: 15px;
    transition: $transition_default;
    line-height: 1.3;

    &:hover:not(.disabled) {
      color: $color_primary;
    }

    &.disabled {
      color: $color_grey;
      cursor: not-allowed;
    }
  }

  .categoryRow {
    @extend .row;
    display: flex;
    justify-content: space-between;
  }
}

.hiddenIcon {
  font-size: 13px;
  color: $color_grey_lighter;
}

.dimensionExamplesList {
  padding-left: 15px;
}

.emptyMessage {
  font-size: 14px;
  color: $color_grey_lighter;
  display: flex;
  justify-content: center;
}
