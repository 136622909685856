@import "scssShared/variables.scss";

.root {
  .fieldset {
    position: relative;
    box-sizing: border-box;
    border-radius: 20px;
    height: 34px;
    border: $border_grey_light_thin;
    background: $color_white;
    box-shadow: $toggle_inset_box_shadow;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input {
    &[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 34px;
      margin: 0;
      cursor: pointer;
      pointer-events: none;
      left: 0;

      &:checked + .label + .switch {
        left: calc(50% - 2px);
      }
    }
  }

  .label {
    width: 50%;
    position: absolute;
    font-size: 11px;
    line-height: 11px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      color: $color_grey_lighter;
      opacity: 0.5;
    }

    &.front {
      color: $color_white;
    }

    &.hidden {
      display: none;
    }
  }

  .switch {
    width: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 28px;
    background-color: $color_primary;
    border-radius: 20px;
    transition: all 0.5s;
    box-shadow: $box_shadow_toggle_active;
    pointer-events: none;
  }

  &.disabled {
    .fieldset {
      background: $color_border;
      cursor: not-allowed;
    }

    .label {
      cursor: not-allowed;
    }

    input {
      &[type="radio"] {
        cursor: not-allowed;

        &:checked + .label {
          color: $color_disabled_field;
        }
      }
    }

    .switch {
      background-color: $color_disabled_field_bg;
    }
  }

  &.sm {
    .fieldset {
      margin: 0;
      height: 28px;
    }

    input {
      &[type="radio"] {
        height: 28px;
      }
    }

    .switch {
      height: 22px;
    }

    .label {
      padding: 8px 0;
      margin-bottom: 0;
    }
  }

  &.xs {
    .fieldset {
      margin: 0;
      height: 24px;
    }

    input {
      &[type="radio"] {
        height: 24px;
      }
    }

    .switch {
      height: 18px;
    }

    .label {
      font-size: 10px;
      line-height: 10px;
      padding: 6px 0;
    }
  }
}
