@import "scssShared/variables.scss";

.toastContainer {
  box-sizing: border-box;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-feature-settings: "calt" 0;

  &:global(.Toastify__toast-container--top-right) {
    top: 80px;
  }

  .toastContent {
    display: flex;
    align-items: center;
  }

  .toast {
    opacity: 0.85;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    border-radius: 8px;
    padding: 12px 20px;
    min-height: 60px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    line-height: 125%;
    font-weight: 400;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: default;

    .toastBody {
      flex-basis: 310px;
      box-sizing: border-box;
      word-wrap: break-word;
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      gap: 10px;
    }

    .toastText {
      max-width: 310px;

      a {
        text-transform: none;
        color: $color_white;
        font-size: 14px;

        &:hover {
          color: $color_white;
          text-decoration: none;
        }
      }

      .externalLink {
        color: inherit;
        display: block;
        font-size: inherit;
        margin-top: 5px;

        &:hover {
          color: inherit;
        }
      }
    }

    .link {
      display: table;
      text-transform: uppercase !important;
      cursor: pointer;
      letter-spacing: 0.15em;
      font-size: 10px !important;
      line-height: 10px;
      border-radius: $br_small;
      transition: $transition_default;
      text-decoration: none;
      flex-shrink: 0;
      white-space: pre;
      font-weight: 500;
      padding: 4px 6px;
      margin-top: 4px;
    }

    .route {
      display: block;
      font-size: 12px;
      margin-top: 5px;
      max-width: 280px;
      overflow: hidden;
      text-decoration: underline;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }
    }

    .buttons {
      text-align: center;
    }

    .toastIcon {
      font-size: 24px;
    }

    .toastClose {
      cursor: pointer;
      font-size: 16px;
      padding: 4px;
      margin-right: -4px;

      &:hover {
        opacity: 0.6;
      }
    }

    &.toastError {
      background: #fdf4f3;
      color: #ed382a;
      border: 1px solid #ed382a;

      .toastClose {
        color: #ed382a;
      }

      .link {
        color: #ed382a;
        border: 1px solid #ed382a;

        &:hover,
        &:active,
        &:focus {
          color: $color_white;
          background: #ed382a;
        }
      }

      .route {
        color: #ed382a;
      }
    }

    &.toastSuccess {
      background: #f4f9f5;
      color: $color_green;
      border: 1px solid $color_green;

      .toastClose {
        color: $color_green;
      }

      .link {
        color: $color_green;
        border: 1px solid $color_green;

        &:hover,
        &:active,
        &:focus {
          color: $color_white;
          background: $color_green;
        }
      }
    }

    &.toastInfo {
      background: $color_white;
      color: $color_black;
      border: 1px solid $color_border;

      .toastClose {
        color: $color_black;
      }

      .link {
        color: $color_primary;
        border: 1px solid $color_primary;

        &:hover,
        &:active,
        &:focus {
          color: $color_white;
          background: $color_primary;
        }
      }
    }

    // &.wide {
    //   width: 440px;

    //   .toastBody {
    //     flex-basis: 355px;
    //   }

    //   .toastText {
    //     max-width: 355px;
    //   }
    // }
  }
}

:global(.toastify-on-top) {
  .toastContainer {
    &:global(.Toastify__toast-container--top-right) {
      top: 1em;
    }
  }
}
