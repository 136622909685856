@import "scssShared/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 20px;

  .label {
    font-size: 11px;
    letter-spacing: 0.1em;
    color: $color_grey;
    text-transform: uppercase;
    font-weight: 700;
    white-space: nowrap;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;
  }

  :global(.tag-picker) {
    margin-right: 0;
  }
}
