@import "scssShared/variables.scss";

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.audienceApiIcon {
  color: $color_green;
  font-size: 20px;
}
