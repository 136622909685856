@import "scssShared/variables.scss";

.setup-attributes {
  .top-bar {
    border-bottom: $border_grey_white_bg;
    padding: 15px 5px 15px 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

    .toggle-hidden {
      font-size: 15px;
      color: $color_grey_lighter;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .labels-filter {
      display: flex;
      gap: 10px;

      .selected-labels {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
      }

      .filter-by {
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 24px;
        color: $color_grey;
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
      }
    }
  }

  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .attribute-name-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .attribute-name {
      font-size: 16px;
      color: $color_black;
      font-weight: 700;
    }

    .badge {
      margin-left: 5px;
    }

    .sub-attrs {
      margin: 0;
      padding: 0;

      li {
        list-style-type: "– ";
        list-style-position: inside;
        font-size: 12px;
        line-height: 16px;
        color: $color_grey_lighter;
        font-weight: normal;
      }
    }
  }

  .isHidden {
    > div {
      opacity: 0.3;
    }
  }

  .source-icon {
    width: 35px;
    margin-right: 10px;
  }

  .source-n-count {
    .count {
      font-size: 12px;
      color: $color_grey;
    }
  }

  .attribute-tags {
    line-height: 35px;
  }

  .attribute-description {
    white-space: pre-line;
  }

  .attribute-label {
    cursor: pointer;
    white-space: normal;
    min-height: 24px;
    height: auto;
    line-height: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0 5px 5px 0;
  }
  .disabled-button-tooltip {
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
  }

  .actions {
    display: flex;
    gap: 5px;
  }
}
