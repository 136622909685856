@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.identity-stitching-form-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px;

  .editable-name {
    width: 420px;

    .editable-name-input {
      width: 360px;
    }
  }

  .disabled-id {
    width: auto;

    label {
      color: $color_grey;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-align: right;
    }

    input {
      width: 330px;
    }
  }
}

.identity-stitching-form {
  padding: 20px;

  .ace-editor-wrapper-with-margin {
    margin-left: 10px;
  }
  .form-row {
    padding: 20px;
    margin: 0;
  }

  label {
    display: block;
  }

  .event-item-wrapper {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .box {
      border: 1px solid $color_border;
      padding: 10px;
      border-radius: $br_small;
      display: flex;
      margin-right: 10px;
    }

    .event-id {
      width: 320px;
    }
  }

  .filter-attribute-select-wrapper {
    .filter-attribute-select-dropdown {
      z-index: 5;
    }
  }
}
