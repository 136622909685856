@import "scssShared/variables.scss";

.app {
  box-sizing: border-box;
  min-width: 1200px;

  .invalid-browser-message {
    background: #f9b549;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color_black;
      height: 50px;

      .left-part {
        display: flex;
        align-items: center;

        .exclamation-icon {
          font-size: 20px;
          display: block;
          margin-right: 15px;
        }

        p {
          font-size: 14px;
          display: block;

          a {
            text-transform: none;
            color: $color_black;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      .right-part {
        display: flex;
        align-items: center;
        .warning-close {
          line-height: normal;
          text-transform: uppercase;
          cursor: pointer;
          letter-spacing: 1.5px;
          font-size: 11px;
          border: 1px solid $color_black;
          border-radius: $br_medium;
          padding: 10px 9px 9px;
          transition: $transition_default;
          text-decoration: none;
          color: $color_black;
          flex-shrink: 0;
          font-weight: 500;

          &:hover {
            color: $color_black;
            background: rgba(255, 255, 255, 0.15);
          }

          &:active,
          &:focus {
            color: $color_black;
            background: rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
  }
}
