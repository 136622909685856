@import "scssShared/variables.scss";

.customerAttributesBoxes {
  width: 100%;
}

.layoutPicker {
  border-left: 1px solid $color_border;
  height: 36px;
  box-sizing: border-box;
  padding: 1px 2px 1px 12px;
  transition: all 250ms ease-in-out;
  width: 83px;
  flex-shrink: 0;
  display: flex;
  flex-wrap: nowrap;

  .layoutButton {
    margin: 4px 2px;

    svg {
      path {
        transition: $transition_default;
      }
    }

    &:hover {
      opacity: 1;

      svg {
        path {
          fill: $color_black;
        }
      }
    }

    &.active {
      svg {
        path {
          fill: $color_black;
        }
      }
    }
  }
}
