@import "scssShared/variables.scss";

.detail-attribute {
  .header-buttons {
    > * {
      margin-left: 10px;
    }
  }

  .loading-indicator {
    margin-top: 20px;
  }
}
