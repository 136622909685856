@import "scssShared/variables.scss";

.headerContent {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .button {
    background: transparent;
    border-color: #cccccc;
    border-radius: $br_large;
    color: #999999;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: normal;
    text-transform: none;
    transition: none;

    &:hover,
    &:focus,
    &.active {
      background: $color_white;
      color: $color_black;

      svg {
        color: $color_warning_red;
      }

      &.checked {
        svg {
          color: $color_green;
        }
      }
    }
  }

  .buttonAddNew {
    background: $color_grey_secondary_menu;

    &:hover,
    &:focus {
      background: $color_grey_secondary_menu;
      color: #999999;

      svg {
        color: #999999;
      }
    }
  }
}

.root,
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.root {
  padding: 20px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  .name {
    width: 359px;
  }
}

.configured {
  box-sizing: border-box;
  padding: 8px 12px;

  color: $color_green;
  border: 1px solid $color_green;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.fileUploadField {
  width: 600px;
}

.fileUploadDesc {
  color: $color_grey_lighter;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.modal {
  width: 800px !important;

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
