@import "scssShared/variables.scss";

.body {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.description {
  font-style: italic;
  color: $color_grey_lighter;
  font-size: 14px;
}

.inputs {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.loadingIndicator {
  grid-column: span 2;
}

.warning {
  background-color: $color_yellow;
  padding: 10px 15px;
  border-radius: $br_medium;
  font-size: 14px;
}

.checkbox {
  justify-self: flex-start;
}
