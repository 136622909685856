@import "scssShared/variables.scss";

.modal {
  box-sizing: border-box;
  max-height: calc(100vh - 150px);
  padding: 0 !important;
  width: 720px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10px 0 30px;

    h3 {
      margin: 0 !important;
    }
  }

  .content {
    box-sizing: border-box;
    padding: 0 30px 30px 30px;
    overflow-x: hidden;
    overflow-y: auto;
    // mozilla
    scrollbar-color: rgba(0, 0, 0, 0.4) $color_white;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 24px;
      height: 0; // no horizontal scrollbar
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4);
      // little trick to make scrollbar thumb round and has horizontal margin
      background-clip: padding-box;
      border-radius: 9999px;
      border: 10px solid $color_white;

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    p,
    li {
      line-height: 1.3;
    }

    p {
      margin: 0 0 15px 0;
    }

    h3 {
      margin: 0 0 10px 0;
    }

    a {
      color: inherit;
      font-size: inherit;
      text-transform: inherit;
      color: $color_primary;
    }

    strong {
      // color: $color_primary;
    }

    .link {
      text-transform: none;
    }

    code {
      font-size: 14px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 10px;

      border: 1px solid #d9d9d9;
      border-radius: $br_large;
    }

    .buttonWrapper {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
    }
  }
}
