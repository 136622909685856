@import "scssShared/variables.scss";

.codesHeader {
  justify-content: space-between;
}

.searchBox {
  width: 300px;
}

.content {
  margin-bottom: 20px;
}

.actions {
  display: flex;
  gap: 5px;
}
