@import "scssShared/variables.scss";

.timelineContent {
  display: flex;
  align-items: flex-start;
}

.leftPanel {
  width: 285px;
  flex-shrink: 0;
  position: sticky;
  top: 0;
}
.rightPanel {
  width: calc(100% - 285px);
}
