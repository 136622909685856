.password-change-form {
  display: flex;
  gap: 20px;

  .field {
    width: 300px;
  }

  .hidden-submit {
    display: none;
  }
}
