@import "scssShared/variables.scss";

.tag {
  color: $color_white;
  font-weight: bold;
  font-size: 9px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  box-sizing: border-box;
  border-radius: $br_small;
  position: relative;
  white-space: nowrap;
  transition: $transition_default;
  background: $color_primary;
  min-width: 0;

  &:hover {
    box-shadow: $bs_large;
  }

  .content {
    max-width: 100%;
    word-wrap: break-word;
  }

  .close {
    opacity: 0;
    font-size: 13px;
    position: absolute;
    left: 0;
    right: 0;
    top: 4px;
    margin: 0 auto;
    transition: $transition_default;
  }

  &.clickable {
    transition: $transition_default;
    cursor: pointer;

    span {
      transition: $transition_default;
    }

    &:hover {
      opacity: 1;
      span {
        opacity: 0.2;
      }
      .close {
        opacity: 1;
      }
    }
  }

  &.small {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
  }

  &.big {
    height: 34px;
    padding: 11px 18px;
    font-size: 11px;

    .close {
      top: 10px;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      background: $color;
    }
  }

  &.site-background {
    background: $color_grey_secondary_menu;
    color: $color_grey_lighter;
    border: 1px solid $color_border;
  }

  &.white {
    background: $color_white;
    color: $color_grey_lighter;
    border: $border_grey_light_thin;
  }

  &.white-red {
    background: $color_white;
    color: $color_warning_red;
    border: 1px solid $color_warning_red;
  }
}

.tag-tooltip {
  max-width: 320px;
  word-break: break-word;
}
