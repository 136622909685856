@import "scssShared/variables.scss";

.paper {
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: $transition_default;

  .paperHeader {
    box-sizing: border-box;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    background-color: $color_catskill-white;
    border-bottom: 1px solid $color_border;
    border-radius: $br_large;
    transition: $transition_default;

    &.expanded {
      border-radius: $br_large $br_large 0 0;
    }

    .wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .backButton {
      width: 18px;

      color: #999999;
      font-weight: 900;
      line-height: 18px;
      letter-spacing: -0.025em;
    }

    .titleWrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    h3 {
      margin: 0;

      color: #222222;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.02em;
    }

    p {
      margin: 0;

      color: #777777;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0em;
    }

    a {
      text-transform: none;
      color: $color_primary;
    }

    .expandButton {
      margin-left: 10px;
    }
  }
}
