@import "scssShared/variables.scss";
@import "scssShared/baseVars.scss";

.container {
  width: 20px;
  height: 20px;
  border-radius: $br_small;
  color: $color_grey;
  border: $border_grey_light_thin;
  background-color: $color_catskill_white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &.active {
    color: $color_primary;
    border-color: $color_primary;
    background-color: rgba($color_primary, 0.1);
  }
}

.tooltip {
  line-height: 1.4;
  h3 {
    color: white;
    margin: 0 0 8px 0;
  }

  ul {
    padding: 0 25px;
    margin: 0;
  }

  ul > li ul > li {
    line-height: 1.6;
  }

  .activationsList {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    list-style: none;
  }

  .activation {
    border-radius: $br_small;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: white;
    color: $color_black;
  }
}

.segment {
  display: flex;
  align-items: center;
  gap: 5px;
}

.segmentId {
  font-size: 10px;
  background-color: $color_gray_200;
  padding: 1px 2px;
  border-radius: $br_small;
  border: $border_grey_light_thin;
  line-height: initial;
}
