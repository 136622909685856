@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.dragHandleCol {
  width: 20px;
}

.tableEnabledCol {
  width: 200px;
}

.tableActionsCol {
  width: 50px;

  .actions {
    display: flex;
    gap: 5px;
  }
}
