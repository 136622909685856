@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.segmentation-numbers-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .segmentation-label {
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
    font-size: 10px;
    letter-spacing: 0.1em;
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .inner-box {
      font-weight: bold;
      color: $color_grey;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .segmented {
        font-size: 14px;
        color: $color_primary;
        min-width: 15px;
        text-align: center;
      }

      .plus-up-to {
        font-weight: 400;
        font-size: 14px;
        color: $color_yellow;
        white-space: nowrap;
      }

      .out-of {
        font-size: 10px;
        text-transform: uppercase;
      }

      .segmentation-total {
        font-size: 14px;
      }

      .na-tooltip {
        font-weight: normal;
        font-size: 11px;
      }

      .percents {
        font-weight: 400;
        font-size: 14px;
        color: $color_grey;
        display: flex;
        align-items: center;
      }
    }

    .progress-bar {
      position: relative;
      height: 3px;
      z-index: 0;
      border-radius: 1.5px;
      background-color: $color_grey;

      .progress-segmented {
        position: absolute;
        background-color: $color_primary;
        z-index: 2;
        border-radius: 1.5px;
        height: 100%;
      }

      .progress-plus-up-to {
        position: absolute;
        background-color: $color_yellow;
        z-index: 1;
        border-radius: 1.5px;
        height: 100%;
      }
    }
  }

  .segmented,
  .segmentation-total,
  .percents {
    &.loading {
      position: relative;
      cursor: wait;

      &:before {
        margin: -8px 0 0 -8px;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        content: "";
        border-radius: 24px;
        background-clip: padding-box;
        border-color: rgba($color_primary, 0.25);
        border-width: 2px;
        border-style: solid;
        border-top-color: $color_primary;
        animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
      }

      span {
        color: transparent;
      }
    }
  }

  &.error {
    .box {
      .error-message {
        font-size: 12px;
        color: $color_warning_red;
        font-weight: 400;
      }
    }
  }
}
