@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.customers-list {
  .customers-search-position {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .customers-search-form {
    display: flex;
    width: 655px;
    box-shadow: $bs_smallest;
    transition: $transition_default;
    border-radius: $br_small;
    position: relative;

    .search-field {
      input {
        padding-left: 265px;

        &:hover {
          border-color: $color_grey;
        }

        &:focus {
          border-color: $color_primary !important;
          box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
        }
      }
    }

    .customers-list-attribute-picker {
      position: absolute;
      left: 0;
      top: 0px;
      z-index: 1;
      width: 250px;
      height: 100%;

      .input-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 100%;
        background-color: $color_catskill-white;
      }
    }

    .search-box {
      width: 100%;
      height: 52px;
      padding-right: 90px;
      box-shadow: none;
      &:hover {
        border-color: $color_border;
      }
    }
  }

  .customers-list-content {
    .number-of-results {
      text-align: right;
      margin-right: 20px;
      color: $color_grey;
      font-size: 15px;
      margin-bottom: 0;
      float: right;
      padding-top: 15px;

      strong {
        color: $color_black;
      }
    }

    .customer-avatar {
      width: 48px;
      height: 48px;
      border: $border_grey_light_thin;
      border-radius: $br_medium;
      float: left;
      padding: 8px;
      box-sizing: border-box;
      background: $color_bg_customer_avatar;
    }

    .favorite-button {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 16px;
      transition: all 0.1 ease;
      color: $color_grey_lighter;

      &.is-faved {
        color: $color_primary;
      }

      &:hover {
        background-color: rgba($color_primary, 0.2);
      }
    }

    .expand-button {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 45px;
      width: 45px;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 16px;
      transition: all 0.1 ease;
      color: $color_grey_lighter;

      &:hover {
        background-color: rgba($color_primary, 0.2);
      }
    }

    .info-message {
      margin: 20px 10px;
      text-align: center;
      font-size: 15px;
      line-height: 18px;
      color: $color_grey;
      > span {
        color: $color_primary;
        font-weight: bold;
      }
      strong {
        color: $color_black;
      }
    }

    .latest-search {
      line-height: 135%;
      display: flex;
      align-items: center;

      .searched-text {
        min-height: 21px;
      }
      .bottom-text {
        color: $color_grey;
        font-size: 12px;
      }
      .last-search-label {
        color: $color_grey_lighter;
        padding-left: 10px;
        padding-right: 5px;
        flex-shrink: 0;
      }
      .loading-indicator {
        flex: 0 0 35px;
      }
      .search-box {
        background: $color_white;
        border: 1px solid $color_very-light-grey;
        box-sizing: border-box;
        /* Drop-shadow-2021 */
        box-shadow: $bs_small;
        border-radius: 8px;
        padding: 10px;
        width: 175px;
        margin: 5px;
        font-size: 15px;
        cursor: pointer;
      }
      .info-message {
        margin-bottom: 0;
        margin-top: 5px;
      }
      .repeat-search {
        font-size: 15px;
        color: $color_primary;
        text-transform: none;
        text-decoration: underline;
        border: 0;
        background: transparent;
        margin: 0 0 5px;
        padding: 0;

        &:hover {
          text-decoration: none;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .customers-search {
      .expandedHidden {
        display: none;
      }

      .expandedVisible {
        display: table-row;
      }

      .avatar {
        width: 66px;
        box-sizing: border-box;
      }

      .chevron-head {
        width: 28px;
        box-sizing: border-box;
      }

      .additional-attribute {
        max-width: 180px;
      }

      .customer-table-cell {
        vertical-align: top;
        font-size: 14px;
        padding-top: 28px;
        padding-bottom: 28px;
        line-height: 18px;

        &.no-border {
          border-bottom: none;
        }

        &.smaller {
          padding-top: 14px;
          padding-bottom: 14px;
        }

        .compound-value-wrapper {
          display: flex;
          max-width: 520px;
          background-color: white;
          padding: 10px;
          border: $border_grey_white_bg;
          border-radius: $br_medium;
        }

        .customer-tooltip {
          max-width: 450px;
          white-space: normal;
          overflow-wrap: break-word;
          padding: 10px;

          &.additional-value-tooltip {
            span {
              font-size: 11px;
              line-height: 13px;
              display: block;
              padding-bottom: 5px;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }

        &.customer-id {
          font-size: 16px;
          color: $color_black;
        }

        &.customer-avatar-cell {
          padding: 12px 8px;
        }

        &.favorite-button-cell {
          padding-top: 20px;
          padding-bottom: 0;
          width: 32px;
        }

        &.no-wrap {
          white-space: nowrap;
        }

        &.chevron-cell {
          .chevron-down {
            color: $color_grey_lighter;
          }
        }
      }
    }
  }
}
