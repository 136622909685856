@import "scssShared/baseVars.scss";
@import "scssShared/variables.scss";

.table {
  display: grid;
}

.header {
  display: contents;
  font-size: 11px;
  font-weight: 700;
  color: $color_gray_400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.headerCell,
.cell {
  padding: 10px 15px;
}

.headerCell {
  display: flex;
  align-items: center;
  position: sticky;
  top: -1px; // There's a 1px gap at the top for some reason
  background-color: rgba(white, 0.8);
  backdrop-filter: blur(8px);
  box-sizing: border-box;
  z-index: 1;

  .header.isSticking & {
    border-bottom: solid 1px $color_gray_200;
  }
}

.label,
.sortButton {
  padding: 2px 4px;
  margin-left: -4px;
}

.sortButton {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.1s ease;

  &:hover,
  &.active {
    color: $color_gray_700;
  }
}

.body {
  display: contents;
}

.row {
  display: contents;
  transition: background-color 0.1s ease;
  text-transform: none;

  &:hover > div {
    background-color: rgba($color_primary, 0.05);
  }
}

.row:not(:last-of-type) .cell {
  border-bottom: solid 1px $color_gray_200;
}

.cell {
  font-size: 14px;
  color: $color_gray_500;
  display: flex;
  align-items: center;
  min-width: 0;
}

.emptyMessage {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.headerCell,
.emptyMessage {
  padding-top: 20px;
}

.name {
  font-weight: 700;
  font-size: 16px;
  color: $color_black;
}

.modifiedBy {
  font-size: 11px;
}
