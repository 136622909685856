@import "scssShared/variables.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.nameRow {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.toggleWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px; // Sorry for the magic number
  width: 200px;
  margin-top: 5px; // And this one
}

.label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 1px;
}

.rolesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectors {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rolesList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.role {
  border: $border_grey_light_thin;
  border-radius: $br_large;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.roleName {
  font-weight: 700;
  color: $color_grey_lighter;
  font-size: 14px;
  line-height: 1;
}
