@import "scssShared/variables.scss";

.textInputs {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .textInput {
    label {
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-transform: none;
      letter-spacing: normal;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
