@import "scssShared/variables.scss";

.root {
  box-sizing: border-box;
  padding: 30px 5px 5px 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  visibility: hidden;

  background: #fff2f0; // has to be solid, cannot use linear gradient or opacity
  border: 1px solid $color_primary;
  border-radius: $br_small;
  box-shadow: $bs_smallest;
  color: $color_primary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  overflow-wrap: anywhere;
  transition: top 400ms ease;

  &.visible {
    top: 20px;
    visibility: visible;
  }

  &.noWrap {
    flex-wrap: nowrap;
    padding: 30px 10px 10px 10px;
  }

  .icon {
    line-height: 12px;
  }
}
