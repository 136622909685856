@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.container {
  position: relative;
}

.tabs {
  position: absolute;
  top: 90px;
  height: 60px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  color: $color_grey_lighter;
}

.tabButton {
  margin: 0 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: all 0.1s ease;
  color: $color_grey_lighter;
  font-weight: 700;
  font-size: 16px;

  &.active {
    border-bottom: 3px solid $color_primary;
    color: $color_black;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.header {
  gap: 20px;

  .name {
    width: 300px;

    label {
      color: $color_grey;
      font-size: 13px;
      letter-spacing: 0.1em;
      line-height: 16px;
      margin: 0 10px 0 0;
    }

    input {
      border-radius: $br_medium;
    }
  }

  .buttons {
    margin-left: auto;
    display: flex;
    gap: 10px;
  }
}

.text {
  color: #999999;
  font-size: 14px;
  line-height: 17px;
}

.application {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 80px;
  margin-bottom: 5px;
  padding: 20px;

  .selectField {
    flex: 1;
  }

  .checkboxes {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.content {
  display: flex;
  padding: 0;
  align-items: stretch;
  margin-bottom: 5px;
}

.formFields {
  padding: 20px;
  flex: 1;

  & > *:not(:first-child) {
    margin-top: 10px;
  }
}

.titleWrapper,
.urlWrapper {
  display: flex;
  align-items: flex-end;

  .titleInput input,
  .urlInput input {
    border-radius: $br_small 0 0 $br_small;
    border-right: none !important;
  }
}

.bodyWrapper {
  position: relative;

  .attributePicker {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
  }
}

.warning {
  margin-top: 5px;
  margin-left: 16px;
  font-size: 11px;
  font-weight: 700;
  color: $color_warning_orange;
}

.label {
  @include input-label-styles;
}

.imageWrapper {
  display: flex;

  & :global(.react-select-redux-field__control) {
    background-color: #f7f9fb;
    border-radius: $br_small 0 0 $br_small !important;
  }

  .imageUrl {
    input {
      height: 44px;
      border-left: none;
      border-right: none;
      border-radius: 0;

      &:hover,
      &:focus {
        border-left: none;
        border-right: none;
        border-radius: 0;
      }
    }
  }
  .imageInput {
    height: 44px;
    border-left: none !important;
    border-radius: 0 $br_small $br_small 0 !important;
  }
}

.usedAttributesList {
  margin-top: 5px;
}

.imageNote {
  color: $color_grey_lighter;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.line {
  margin: 20px 0 15px 0 !important;
  padding: 0;
  border: 0;
  border-top: 1px solid #cccccc; // Chrome, Safari
  background-color: #cccccc; // Firefox, Opera
  color: #cccccc; // IE7+
}

.customData {
  padding: 20px;

  &.error {
    border: $border_warning_red;
    box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
  }

  .header {
    display: flex;
    justify-content: space-between;

    &.expanded {
      margin-bottom: 15px;
    }

    .text {
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0;
        color: $color_black;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: -0.025em;
      }

      p {
        color: #777777;
        margin: 0;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .expandButton {
      color: #777777;
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.sendTestTo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;
  margin-top: 5px;

  .selectField {
    flex: 1;
  }

  .textField {
    width: 350px;
  }
}
