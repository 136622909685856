@import "scssShared/variables.scss";

.twoCols {
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    width: calc(50% - 5px);
    flex-shrink: 0;
  }

  .left {
    margin-right: 10px;
  }
}

.customerAttributesPaper {
  padding: 0px;
  position: relative;
  margin-bottom: 10px;
  border-top: 3px solid $color_primary;

  &.invisible {
    width: calc(50% - 10px);
    visibility: hidden;
    position: absolute;
  }

  .boxHeader {
    background: $color_catskill-white;
    min-height: 52px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: $bs_smallest;
    border-radius: $br_medium;

    .boxHeaderActions {
      display: flex;

      .caret {
        margin-left: 3px;
        margin-right: -12px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey_lighter;
      margin: 0;
      padding-left: 14px;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color_primary;
        position: absolute;
        left: 0px;
        top: 50%;
        margin-top: -5px;
      }
    }
  }

  .boxContent {
    border-top: 1px solid $color_border;
    transition: all 0.2s ease-in-out;
    overflow-y: hidden;

    .customerAttributesValues {
      padding: 5px 15px 15px;
      font-size: 14px;

      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: $border_grey_white_bg;
        padding: 0 5px;
        overflow: hidden;

        &.compoundAttrView {
          display: block;

          .attrvalue {
            justify-content: flex-start;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        .attrname {
          flex-basis: 50%;
          max-width: 50%;
          padding-top: 0;
          padding-bottom: 0;
          vertical-align: top;
          padding-right: 20px;
          box-sizing: border-box;

          .nameTags {
            display: flex;
            flex-wrap: wrap;
            min-height: 32px;
            padding-top: 4px;
            padding-bottom: 4px;
            align-items: center;
          }

          .nameWrapper {
            font-weight: bold;

            .name {
              margin-right: 4px;
            }
          }

          .tag {
            margin: 2.5px 5px 2.5px 0;
          }
        }
        .attrvalue {
          word-break: break-word;
          flex-basis: 50%;
          justify-content: flex-end;
          display: flex;
          align-items: center;
          text-align: right;
          padding: 10px 0;

          .valueWrapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: flex-end;

            img {
              max-width: 60px;
              height: auto;
            }

            a {
              text-transform: inherit;
              letter-spacing: inherit;
              font-size: inherit;
            }
          }

          .modal {
            word-break: normal;
            text-align: initial;

            .text {
              color: #222222;
              font-size: 16px;
              line-height: 24px;
            }

            .buttons {
              display: flex;
              justify-content: space-between;
              margin-top: 15px;
            }
          }

          .showMoreAttributes {
            margin-top: 10px;
            height: 24px;
            padding: 0 12px;
            font-size: 9px;

            &.loading {
              &:before {
                width: 14px;
                height: 14px;
                margin: -9px 0 0 -9px;
              }
            }
          }
        }

        .attrname,
        .attrvalue {
          &.noValue {
            color: $color_border;
          }
        }
      }
    }
  }

  .hideEmptyFields {
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $color_border;
    display: flex;
    align-items: center;

    .label {
      margin-right: 5px;
      transition: color 0.15s ease-in;

      &.orange {
        color: $color_primary;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      border-top-color: $color;

      .boxHeader h3::before {
        background-color: $color;
      }
    }
  }
}

.noResultsFound {
  p {
    font-weight: bold;
    margin: 5px 0;
    text-align: center;
  }
}

.compoundTableWrapper {
  max-width: 100%;
}
