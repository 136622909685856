@import "scssShared/variables.scss";

.header {
  position: relative;
}

.search {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttons {
  display: flex;
  gap: 5px;
}

.nameWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.badge {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.05em;
  border: $border_grey_light_thin;
  padding: 4px 6px;
  line-height: 1;
  border-radius: $br_medium;
}

.selectTemplate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;

  .title {
    margin: 0;
    padding: 0;

    color: #222222;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 22px;
  }

  .buttons {
    display: flex;
    gap: 10px;

    .button {
      padding: 9px 10px 9px 10px;

      border-radius: $br_large;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: normal;
      text-transform: none;

      &.grey {
        background: #f5f8fa;
        border-color: #cccccc;
        color: $color_black;
      }
    }
  }
}

.systemTemplates {
  display: flex;
  flex-wrap: wrap;

  .link {
    flex: 0 0 50%;

    outline: none;
    text-decoration: none;
    text-transform: none;
  }
}
