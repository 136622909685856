@import "scssShared/variables.scss";

.colors-wrapper {
  position: relative;

  .error-message {
    margin: 4px 0 0 17px;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: $color_warning_red;
    position: absolute;
  }

  > label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    color: $color_grey;
    margin-bottom: 3px;
  }
}

.colors-options-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  &.disabled label {
    cursor: not-allowed;
  }

  label {
    display: block;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    transition: $transition_default;

    span {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      text-align: center;
      color: $color_white;
      align-items: center;
      justify-content: center;

      .icon {
        display: block;
      }

      @each $name, $color in $colors {
        &.#{$name} {
          background: $color;
        }
      }

      &.white {
        background: $color_white;
        border: 1px solid $color_border;
        box-sizing: border-box;
        color: $color_black;
      }
    }
  }

  .color-opt {
    display: none;

    &:checked + label {
      opacity: 1;
    }
  }

  &.active {
    .marked {
      opacity: 1;
    }
    label {
      opacity: 0.5;
    }
  }

  .already-used {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 6px;
      background-color: $color_white;
      opacity: 0.75;
      position: absolute;
      top: 22px;
      left: 0;
      transform: rotate(-45deg);
    }
  }
}
