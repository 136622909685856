@import "scssShared/variables.scss";

.badge {
  color: $color_primary;
  font-size: 8px;
  border: 1px solid $color_primary;
  border-radius: $br_small;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 3px;
  box-sizing: border-box;
  vertical-align: middle;

  &.mr {
    margin-right: 4px;
  }
}
