@import "scssShared/variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 5px;

  &:hover {
    background: #fe7f661a;

    .createCampaign {
      opacity: 1;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.image {
  width: 80px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  border-radius: $br_large;
  border: 1px solid #cccccc;
  flex-shrink: 0;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin: 0;
  padding: 0;

  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
}

.description {
  margin: 0;
  padding: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.015em;
}

.createCampaign {
  color: $color_primary;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  opacity: 0;
  flex-shrink: 0;
}
