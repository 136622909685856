@import "scssShared/variables.scss";

.edit {
  min-width: 160px;
  height: 45px;
  display: flex;
  align-items: center;
}

.view {
  min-height: 45px;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;

  color: $color_grey;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
}

.value {
  font-weight: bold;
}

.button {
  background-color: $color_very-light-grey;
  color: $color_white;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 5px;
  flex-shrink: 0;
  font-size: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.05s ease;

  &:hover,
  &:focus {
    background-color: darken($color_very-light-grey, 5%);
  }
}

.saveButton {
  @extend .button;
  background-color: $color_primary;
  margin-left: -12px;
  z-index: 11;

  &:hover,
  &:focus {
    background-color: darken($color_primary, 5%);
  }
}
