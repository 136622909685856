@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.actions {
  display: flex;
  gap: 5px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
