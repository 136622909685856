@import "scssShared/variables.scss";

.toggle-switch,
.toggle-switch-multiple {
  text-align: center;
  display: inline-block;

  .fieldset {
    position: relative;
    box-sizing: border-box;
    border-radius: 20px;
    height: 34px;
    border: $border_grey_light_thin;
    background: $color_white;
    box-shadow: $toggle_inset_box_shadow;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input {
    &[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 34px;
      margin: 0;
      cursor: pointer;
      pointer-events: none;
      left: 0;

      &:checked + label,
      &:checked + .toggle-icon {
        color: $color_white;
        opacity: 1;
        pointer-events: none;
        z-index: 1;
      }

      &:checked + label + .switch,
      &:checked + .toggle-icon + .switch,
      &:checked + label:nth-of-type(n) + .switch,
      &:checked + .toggle-icon:nth-of-type(n) + .switch {
        left: calc(50% - 2px);
      }
    }

    &.left-field {
      right: 0;
    }

    &.right-field {
      left: 0;
    }
  }

  label,
  .toggle-icon {
    width: 50%;
    position: relative;
    float: left;
    font-size: 11px;
    line-height: 11px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    color: $color_grey_lighter;
    opacity: 0.5;
    text-align: center;
    cursor: pointer;
    margin: 0;
  }

  .switch {
    width: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 28px;
    background-color: $color_primary;
    border-radius: 20px;
    transition: all 0.5s;
    box-shadow: $box_shadow_toggle_active;
    pointer-events: none;
  }

  &.disabled {
    .fieldset {
      background: $color_border;
      cursor: not-allowed;
    }
    label,
    .toggle-icon {
      cursor: not-allowed;
    }
    input {
      &[type="radio"] {
        cursor: not-allowed;

        &:checked + label {
          color: $color_disabled_field;
        }
      }
    }
    .switch {
      background-color: $color_disabled_field_bg;
    }
  }

  &.small {
    .fieldset {
      margin: 0;
      height: 28px;
    }

    input {
      &[type="radio"] {
        height: 28px;
      }
    }

    .switch {
      height: 22px;
    }

    label,
    .toggle-icon {
      font-size: 10px;
      line-height: 10px;
      padding: 6px 0;
    }
  }

  &.tiny {
    .fieldset {
      margin: 0;
      height: 24px;
    }

    input {
      &[type="radio"] {
        height: 24px;
      }
    }

    .switch {
      height: 18px;
    }

    label,
    .toggle-icon {
      font-size: 10px;
      line-height: 10px;
      padding: 6px 0;
    }
  }
}

.toggle-switch-wrapper {
  &.on-off-type {
    label {
      color: transparent !important;
    }

    .labels {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: flex;
      justify-content: space-between;
      color: $color_white;
      text-transform: uppercase;
      font-weight: bold;
      box-sizing: border-box;

      .offLabel {
        visibility: hidden;
      }
    }

    .on-off-background {
      background: $color_green;
      box-shadow: $toggle_inset_box_shadow;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 20px;
      cursor: pointer;
      z-index: 0;
      transition: $transition_default;
    }

    .switch {
      box-sizing: border-box;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%), #ffffff;
      box-shadow: $box_shadow_toggle_active;
      color: $color_grey;
      top: 3px;
      left: 3px;
      z-index: 1;

      &.sm {
        width: 18px;
        height: 18px;
      }
    }

    .fieldset {
      background: transparent;
      box-shadow: none;
      border: 0;
      height: 34px;
      width: 70px;

      .switch {
        width: 28px;
        height: 28px;
      }

      .labels {
        font-size: 12px;
        line-height: 12px;
        padding-top: 11px;

        .onLabel {
          margin-left: 13px;
        }

        .offLabel {
          margin-right: 11px;
        }
      }

      input {
        &[type="radio"] {
          &:checked + label + .switch,
          &:checked + .toggle-icon + .switch,
          &:checked + label:nth-of-type(n) + .switch,
          &:checked + .toggle-icon:nth-of-type(n) + .switch {
            left: 39px;
          }
        }
      }

      &.sm {
        height: 24px;
        width: 60px;

        .switch {
          width: 18px;
          height: 18px;
        }

        .labels {
          font-size: 11px;
          line-height: 11px;
          padding-top: 7px;

          .onLabel {
            margin-left: 11px;
          }

          .offLabel {
            margin-right: 7px;
          }
        }

        input {
          &[type="radio"] {
            &:checked + label + .switch,
            &:checked + .toggle-icon + .switch,
            &:checked + label:nth-of-type(n) + .switch,
            &:checked + .toggle-icon:nth-of-type(n) + .switch {
              left: 39px;
            }
          }
        }
      }
    }

    input {
      &.left-field {
        &:checked ~ .on-off-background {
          background: $color_grey;
        }

        &:checked ~ .labels {
          .onLabel {
            visibility: hidden;
          }
          .offLabel {
            visibility: visible;
          }
        }
      }
    }
  }

  label,
  .toggle-icon {
    display: block;
  }

  .toggle-switch {
    .fieldset {
      margin-top: 0;

      &.small {
        height: 28px;
      }

      .switch.small {
        height: 22px;
      }

      label.small {
        padding: 8px 0;
        margin-bottom: 0;
      }

      &.tiny {
        height: 24px;

        input[type="radio"] {
          height: 24px;
        }
      }

      .switch.tiny {
        height: 18px;
      }
    }
  }

  .toggle-switch-form-field {
    input {
      &[type="radio"] {
        pointer-events: auto;
      }
      &:not(:checked) {
        z-index: 2;
      }
    }
  }
}

.toggle-switch-icon-container {
  display: flex;
  align-items: center;

  .toggle-icon-switch {
    margin: 0 10px;

    .fieldset {
      margin: 0;
    }
  }

  > label {
    margin: 0;
  }
}

.toggle-switch-multiple {
  .fieldset {
    margin: 0;
  }

  label {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner {
    width: calc(100% - 4px);
    margin-left: 2px;
    position: relative;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
}
