@import "scssShared/variables.scss";
@import "scssShared/smartSegmentGroupColors.scss";

.smart-segments-list {
  .container {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .top-bar {
    display: flex;
    justify-content: flex-end;
  }

  .smart-segments-header {
    justify-content: space-between;
  }

  .info-message {
    margin: 20px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .segments-group {
    padding: 5px 2.5px;
    margin-top: 10px;
    box-sizing: border-box;

    &.bigger-margin {
      margin-top: 20px;
    }

    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin: 10px 0 10px;
      color: $color_grey;
    }

    .segments {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .segment-tile {
      margin: 5px 2.5px 0;
      width: calc(33.33% - 5px);
      flex-shrink: 0;
      box-sizing: border-box;
    }

    @each $name, $color in $smartSegmentGroupColors {
      &.#{$name} {
        h3 {
          color: $color;
        }
      }
    }
  }
}
