@import "scssShared/variables.scss";
@import "scssShared/baseVars.scss";

.tooltip :global(.tippy-content) {
  max-width: 260px;
  padding: 10px;
  box-sizing: border-box;
}

.schedule {
  background: $color_primary;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 5px;
  min-height: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      background: $color;
    }
  }

  img {
    width: 24px;
    height: auto;
    flex-shrink: 0;
    margin: 0 5px 0 0;
  }

  p {
    margin: 0;
    color: $color_white;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
  }
}

.icon {
  width: 22px;
  height: 20px;
  color: $color_grey;
  border-radius: $br_small;
  border: 1px solid $color_border;
  box-sizing: border-box;
  background: #f9f9f9;
  margin-right: 12px;
  flex-shrink: 0;
  font-size: 11px;
  font-weight: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:not(.active) {
    &:after {
      content: "";
      width: 16px;
      height: 3px;
      box-sizing: border-box;
      border-top: 1px solid #f9f9f9;
      border-bottom: 1px solid #f9f9f9;
      position: absolute;
      background-color: $color_grey;
      transform: rotate(-45deg);
    }
  }

  &.active {
    background: rgba(254, 127, 102, 0.1);
    border-color: $color_primary;
    color: $color_primary;
  }
}
