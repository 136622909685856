.steps {
  opacity: 0.5;
}

.root {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  padding: 20px;
}

.selectFieldWrapper {
  width: 35%;
}
