@use "scssShared/fonts";
@import "scssShared/animations.scss";
@import "scssShared/form.scss";
@import "scssShared/variables.scss";
@import "scssShared/table.scss";
@import "scssShared/dateTimePicker.scss";
@import "scssShared/chartsCommon.scss";

:root {
  font-family: fonts.$inter_family;
}

input,
textarea,
button {
  font-family: inherit;
}

// to prevent arco.css from overriding font, font-family inherit doesn't work
nav,
li {
  font-family: fonts.$inter_family;
}

html {
  color: $color_black;
  font-size: 1em;
  margin: 0;
  height: 100%;
  background-color: $color_grey_site_background;
}

body.app-body {
  padding: 0;
  font-size: 100%;
}

body,
#root,
.app {
  height: 100%;
  margin: 0;
  background-color: $color_grey_site_background;
  line-height: normal;
  -webkit-font-smoothing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: $color_black;
}

button {
  cursor: pointer;
}

button,
a {
  &:focus {
    outline: none;
  }
}

a {
  cursor: pointer;
  transition: $transition_default;
  font-size: 11px;
  text-transform: uppercase;
  color: $color_grey_lighter;
  text-decoration: underline;
  outline: none;
  transition: text-decoration-color 0.08s ease;

  &:hover {
    text-decoration-color: transparent;
  }
}

.tippy-box[data-placement^="bottom-start"] {
  .tippy-arrow {
    transform: translate3d(6px, 0px, 0px) !important;
  }
}

.tippy-box {
  .tippy-content {
    font-size: 12px;
    line-height: 14px;
    color: $color_white;
    padding: 10px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;

    p {
      margin: 0 0 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-transform: none;
      font-size: 12px;
      color: $color_primary;
    }
  }
}
