@import "scssShared/variables.scss";

.container {
  position: relative;
  box-sizing: border-box;
}

.inputWrapper {
  position: relative;
  display: flex;
  background-color: $color_catskill-white;
  box-sizing: border-box;
  padding: 10px 8px;
  border: $border_grey_light_thin;
  transition: $transition_default;
  align-items: center;
  color: $color_grey_lighter;
  cursor: pointer;

  border-bottom-right-radius: $br_small;

  &:not(.inTextArea) {
    border-top-right-radius: $br_small;
  }

  &.inTextArea {
    border-top-left-radius: $br_small;
  }

  &.error {
    border-color: $color_warning_red;
  }

  &.open {
    border-color: $color_primary;

    .input {
      width: 200px;
    }
  }

  &:hover:not(.error):not(.readOnly):not(.open) {
    border-color: $color_grey_lighter;
  }

  .icons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .bracketsIcon {
    font-size: 22px;
  }

  .ellipsisIcon {
    font-size: 13px;
    position: absolute;
  }

  .input {
    border: none;
    outline: none;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: transparent;
    font-size: 15px;
    width: 0;
    transition: $transition_default;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
  }

  .caretWrapper {
    font-size: 13px;
    padding: 0 2px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
