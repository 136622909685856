@import "scssShared/variables.scss";

.section {
  padding: 20px;
  display: flex;

  &:nth-child(even) {
    background-color: $color_catskill-white;
  }

  &:not(:last-child) {
    border-bottom: $border_grey_light_thin;
  }

  &:first-child {
    border-top-left-radius: $br_small;
    border-top-right-radius: $br_small;
  }

  &:last-child {
    border-bottom-left-radius: $br_small;
    border-bottom-right-radius: $br_small;
  }
}

.description {
  flex: 0 0 240px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 5px;
  }

  p {
    font-size: 12px;
    color: $color_grey_lighter;
    margin: 0;
  }

  a {
    text-transform: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    color: $color_primary;
  }
}

.content {
  flex: 1;
}

.generalSection {
  @extend .section;
}

.permissionsSection {
  @extend .section;

  .content {
    display: flex;
    gap: 30px;

    & > div {
      flex: 1;
    }

    .featuresSection {
      &:first-child .header > h3 {
        margin-top: 0;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > h3 {
          margin: 24px 0 8px 0;
        }
      }

      .sectionButtons {
        display: flex;
        gap: 10px;
        align-items: center;
        color: $color_border;
        font-size: 13px;

        & > button {
          text-transform: unset;
          letter-spacing: unset;
          font-weight: 600;
          font-size: 13px;
        }
      }

      .row:not(:last-child) {
        border-bottom: $border_grey_light_thin;
      }

      .main,
      .export {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
      }

      .main {
        padding: 8px 0;
      }

      .exports {
        padding-bottom: 4px;
      }

      .export {
        padding: 4px 20px 4px 0;
      }

      .info {
        color: $color_grey_lighter;
      }
    }
  }
}

.attributesSection {
  @extend .section;

  .content {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
}
