@import "scssShared/variables.scss";

.headerButtons {
  display: flex;
  gap: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.paper {
  padding: 20px;

  h3 {
    margin: 0;
  }
}

.inviteToggle {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  color: $color_black_lighter;
  font-size: 15px;
}

.user {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &:not(:first-of-type) {
    padding-top: 15px;
    border-top: $border_grey_light_thin;
  }
}

.credentials {
  display: flex;
  gap: 10px;
  align-items: center;

  .avatar {
    flex: 0 0 44px;
    height: 44px;
    align-self: flex-end;
  }

  .iconWrapper {
    height: 44px;
    align-self: flex-end;
    display: flex;
    align-items: center;
  }
}

.addUserButton {
  align-self: flex-start;
}

.roleSection {
  display: flex;
  gap: 30px;
  align-items: center;
  border-bottom: $border_grey_light_thin;
  padding-bottom: 20px;

  .rolePicker {
    width: 400px;
  }
}

.segmentsSection {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
  }

  .filters {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    flex: 1;
  }

  .selectors {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
  }

  .segmentsList {
    display: grid;
    gap: 15px 30px;
    grid-template-columns: repeat(3, 1fr);
  }

  .segment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .segmentName {
      font-weight: 600;
      font-size: 15px;
      flex: 1;
      color: $color_grey_lighter;

      &.checked {
        color: $color_black;
      }
    }

    :global(.fieldset) {
      margin: 0;
    }
  }

  .emptyMessage {
    color: $color_grey_lighter;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
}
