@import "scssShared/variables.scss";

.segments-list {
  .segments-search {
    width: 280px;
  }

  .paper {
    .tag-filter {
      justify-content: space-between;
      padding: 5px 5px 10px 5px;

      .tags-wrapper {
        display: flex;
        align-items: center;
        max-width: 55%;

        .label-tags {
          display: flex;
          align-items: center;

          .selected-tags {
            flex-shrink: 0;
          }

          .tags-and-picker {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }
        }

        .tag-picker {
          margin-right: 0;
        }
      }

      .segment-filters {
        display: flex;

        .segment-filters-label {
          font-size: 11px;
          letter-spacing: 1px;
          margin-right: 10px;
          line-height: 34px;
          color: $color_grey;
          text-transform: uppercase;
          font-weight: bold;
        }

        .segment-filter-button {
          margin-right: 5px;

          .button-icon {
            margin-right: 2px;
          }
        }
      }
    }
  }

  .segment-forbidden-tooltip {
    padding: 10px;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
  }

  .segment-name {
    font-size: 16px;
    font-weight: 700;
    color: $color_black;
    display: flex;
    align-items: center;
    padding: 10px 0;
    max-width: 100%;

    .segment-name-text {
      word-wrap: break-word;
      min-width: 0;
    }
  }

  .tags-cell {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    min-width: 0;

    .tag {
      cursor: pointer;
    }
  }

  .modified-by {
    font-size: 11px;
  }

  .disabled-segment > div {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
