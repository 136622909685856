@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.bannersBox {
  margin-top: 10px;
  margin-bottom: 10px;
}

.searchField {
  width: 315px;
}

.elementIdTooltip {
  word-break: break-all;
}

.image {
  padding-top: 25px;
}

.editLink {
  text-transform: inherit;
}

.actions {
  display: flex;
  gap: 5px;
}
