.page {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.firebaseProjectConfiguration,
.applicationSettings {
  padding: 20px;
}
