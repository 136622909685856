@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.eventsHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .infoTooltipIcon {
    font-size: 14px;
    color: $color_grey;
    margin-left: 10px;
  }
}

.eventsContainer {
  .searchBox {
    position: absolute;
    left: 50%;
    margin-left: -240px;
  }

  .eventSourceFlexWrapper {
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    @include flexAcross();
    align-items: center;

    img {
      width: 35px;
      padding-right: 12px;
    }

    .sourceCount {
      @include flexDown();

      .count {
        font-size: 12px;
        color: $color_grey;
      }
    }
  }
}
