@import "scssShared/variables.scss";

.modifiedAt,
.modifiedBy {
  display: block;
}

.modifiedBy {
  font-size: 11px;
}

.yesIcon,
.noIcon {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
}

.yesIcon {
  color: $color_green;
}

.noIcon {
  color: #f9b549;
}
