@import "scssShared/variables.scss";

.trashButtonWrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  display: flex;
  justify-content: flex-end;
}

.search {
  width: 300px;
}

.paper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  .name {
    word-break: break-all;
  }
}

.actions {
  display: flex;
  gap: 5px;
}

.primary {
  font-weight: bold;
  color: $color_primary;
}

.boltIcon {
  color: $color_primary;
}

.warningIcon {
  color: #f9b549;
}
