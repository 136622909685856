@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";
@import "scssShared/animations.scss";

.container {
  box-sizing: border-box;

  &.hasError .inputs {
    border: $border_warning_red;
    box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
    animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.hasWarning .inputs {
    position: relative;
    z-index: 10;
  }
}

.labelWrapper {
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.hint {
  font-size: 12px;
  color: $color_grey;
}

.label {
  @include input-label-styles;
}

.inputs {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;

  // copied from input-styles mixin because I had to remove `:read-only` styling
  border: $border_grey_light_thin;
  border-radius: $br_small;
  font-size: 15px;
  line-height: 18px;
  padding: 0 15px;
  transition: $transition_default;
  background: $color_white;

  &:hover {
    border: $border_grey;
  }

  &:focus {
    outline: none;
    border: $border_primary_thin;
    box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
  }

  .container.disabled & {
    border: $border_grey_light_thin;
    background: #e6e6e6;
    cursor: not-allowed;

    input {
      cursor: not-allowed;
    }
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: min-content;

  .ghostText {
    height: 0;
    overflow: hidden;
    white-space: pre;
  }

  input {
    border: none;
    outline: none;
    font-size: inherit;
    padding: 12px 0;
    width: 100%;
  }

  &:last-child {
    flex: 1;
  }
}

.separator {
  color: $color_grey;
  font-size: 12px;
}

.warningWrapper {
  position: relative;
  width: 100%;
}
