@import "scssShared/variables.scss";

.settingsForm {
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
}

.formContent {
  box-sizing: border-box;
  padding: 10px 0 0;
  width: 100%;
}

.formHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;

  h3 {
    font-size: 21px;
    margin: 0;
  }

  button {
    margin-top: -4px;
  }

  .expandButton {
    margin-right: -9px;
    margin-top: -3px;
  }
}

.formRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;

  &:not(:last-child) {
    border-bottom: 1px solid $color_border;
  }
}

.settingTexts {
  margin-left: 20px;

  &.pageViewsCountSettingText {
    margin-left: 78px;
  }

  h3 {
    font-size: 16px;
    letter-spacing: normal;
    margin: 0;
  }

  p {
    color: $color_grey_lighter;
    font-size: 12px;
    margin: 2px 0 0;
  }
}

.tooltipWarning {
  p {
    color: $color_white;
  }
}

.formFields {
  display: flex;
  align-items: center;

  > span {
    display: block;
    color: $color_black;
    font-size: 14px;
    margin: 0 10px;
  }
}

.frequencyCapCountField,
.frequencyCapSizeField,
.pageViewsCountField {
  input {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.frequencyCapTypeField {
  min-width: 100px;

  label {
    display: none;
  }
}

.frequencyCapSizeField {
  width: 70px;
}

.frequencyCapSizeField,
.frequencyCapCountField {
  > div {
    position: absolute;
  }
}

.pageViewsCountField {
  width: 170px;

  > div {
    position: absolute;
  }
}

.frequencyCapCountFieldWrapper,
.pageViewsCountFieldWrapper {
  position: relative;

  .frequencyCapCountField {
    input {
      width: 115px;
      padding-right: 65px;
    }
  }

  .afterFieldLabel {
    position: absolute;
    background: $color_catskill-white;
    color: $color_grey_lighter;
    height: 42px;
    border-left: 1px solid $color_border;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 15px;
    letter-spacing: -0.025em;
    padding: 0 7px;
    top: 1px;
    right: 1px;
  }

  .pageViewsCountField {
    input {
      padding-right: 105px;
    }
  }
}

.inlineLabelField {
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
    font-size: 14px;
  }
}

.formContentLeft {
  display: flex;
  align-items: center;
}

.submitButton {
  margin-left: 10px;
}

.tooltipWarning {
  width: 240px;
}
