@import "scssShared/variables.scss";

.trash-button-wrapper {
  border-bottom: $border_grey_white_bg;
  padding: 5px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.setup-destinations {
  .icon-container {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color_border;
    box-sizing: border-box;
    border-radius: 50%;

    img {
      width: 80%;
      height: auto;
    }
  }

  .actions {
    display: flex;
    gap: 5px;
  }

  .select-input {
    width: 100%;

    .tag_select__single-value {
      color: $color_grey_lighter;
    }
  }
}
