@import "scssShared/variables.scss";

.container {
  display: flex;
  width: 480px;
  height: 50px;
  position: relative;
  z-index: 2;

  .select {
    width: 200px;
    height: 50px;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 2;

    :global(.caret-down) {
      font-size: 13px;
    }
  }

  .search :global(.search-field) input {
    padding-left: 215px;
  }

  :global(.react-select-redux-field__control) {
    height: 50px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $color_border;
    background: #eeeeee;
    box-shadow: none;

    &:hover {
      background: #dddddd;
      border-color: $color_border;
      box-shadow: none;
    }

    &:global(.react-select-redux-field__control--menu-is-open) {
      background: $color_primary;
      color: $color_white;

      :global(.react-select-redux-field__placeholder) {
        color: $color_white;
      }

      :global(.react-select-redux-field__input) {
        color: $color_white;
      }

      :global(.caret-down) {
        color: $color_white;
      }
    }
  }

  :global(.react-select-redux-field__indicator-separator) {
    display: none;
  }
}
