@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

@mixin title-style {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.025em;
}

.wrapper {
  box-sizing: border-box;
  position: absolute;
  z-index: 4;
  margin-top: 5px;
  width: 310px;

  background: $color_white;
  border: $border_grey_light_thin;
  border-radius: $br_small;
  box-shadow: $bs_smallest;
}

.header {
  position: sticky;
  top: 0;
  padding: 15px;
  z-index: 1;
  display: flex;
  align-items: center;

  background: $color_grey_secondary_menu;
  border-bottom: $border_grey_light_thin;
  color: $color_primary;
  word-break: break-word;
}

.clickableTitle {
  @include title-style;
  cursor: pointer;
  text-transform: capitalize;
}

.title {
  @include title-style;
  color: $color_grey;
  text-transform: uppercase;
}

.icon {
  cursor: pointer;
  pointer-events: none;

  &.left {
    margin-right: 5px;
  }
}

.bar {
  max-height: 310px;
  overflow-y: auto;
  padding: 15px;
}

.list {
  margin: 0;
  padding: 0;

  color: $color_grey_lighter;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.025em;
  line-height: 14px;
  list-style: none;
  transition: all 0.15s ease-out;
  word-break: break-word;
}

.clickableListItem {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  align-items: center;

  cursor: pointer;
  text-transform: none;

  &.active,
  &:hover:not(.disabled) {
    color: $color_primary;
  }

  &.category {
    text-transform: capitalize;

    &.lookalike {
      border-top: $border_grey_light_thin;
      margin-top: 5px;
      padding-top: 10px;
    }
  }

  &.subItem {
    margin-left: 10px;
  }

  &.disabled {
    color: $color_grey;
    cursor: not-allowed;
  }
}

.categoryListItem {
  color: $color_border;
  text-transform: capitalize;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  .list {
    margin-top: 5px;
  }
}

.categoryName {
  display: flex;
  gap: 5px;
  align-items: center;
  line-height: 1;
}

.lookalikeIconWrapper {
  width: 20px;
  height: 20px;
  background-color: $color_yellow;
  border-radius: $br_small;
  display: flex;
  justify-content: center;
  align-items: center;
}
