@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.content {
  padding: 20px;

  .label {
    @include input-label-styles;
  }

  .aceEditor {
    border: 1px solid $color_border;
    border-radius: $br_large $br_large 0 0;
    box-sizing: border-box;
    z-index: 0;

    &.error {
      @include input-error-animation;
    }
  }

  .preview {
    padding: 20px;

    background-color: $color_white;
    border: 1px solid #cccccc;
    border-radius: 0 0 $br_large $br_large;
    border-top: none;

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      margin: 0 0 10px;
      color: $color_black;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.02em;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}
