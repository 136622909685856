@import "scssShared/variables.scss";

.featuredSegments {
  .searchBar {
    width: 280px;
  }

  .segmentName {
    font-size: 16px;
    font-weight: 700;
    color: $color_black;
    display: flex;
    align-items: center;
    padding: 10px 0;
    max-width: 100%;

    .segmentNameText {
      word-wrap: break-word;
      min-width: 0;
    }
  }

  .tagsCell {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .tag {
      cursor: pointer;
    }
  }

  .modifiedBy {
    font-size: 11px;
  }

  .tagsRow {
    display: flex;
    align-items: center;
    padding: 5px 5px 10px 5px;
    border-bottom: $border_grey_white_bg;

    .tagsFilter {
      flex: 1;
      display: flex;
    }

    .label {
      font-size: 11px;
      letter-spacing: 1px;
      margin-right: 10px;
      line-height: 24px;
      color: $color_grey;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
    }

    .tagsAndPicker {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .tag {
        margin-right: 5px;
      }
    }
  }

  .disabledSegment > div {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
