@import "scssShared/variables.scss";

.footer-container {
  padding: 40px 0;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-top: auto;

  .footer-content {
    display: flex;

    .vertical-delimiter {
      width: 1px;
      height: 100%;
      background: $color_border;
      margin: 0 20px;
    }

    > img {
      &.client-logo {
        filter: brightness(0) saturate(100%) invert(78%) sepia(6%) saturate(8%) hue-rotate(22deg)
          brightness(100%) contrast(94%);
      }
    }

    .meiro-footer-logo {
      height: 40px;
      width: auto;
    }
    .client-logo {
      display: none;
      height: 40px;
      width: auto;

      &.loaded {
        display: block;
      }
    }
  }
}
