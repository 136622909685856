@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.parameters-wrapper {
  position: relative;

  .parameters-button {
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:hover {
      box-shadow: none;
    }

    &.parameters-exist {
      border-color: $color_primary;
      background: rgba($color_primary, 0.1);
      color: $color_primary;
    }

    .code-icon {
      margin-right: 4px;
    }
  }

  .parameters-modal {
    position: absolute;
    z-index: 2;
    width: 380px;
    background: $color_white;
    border: $border_grey_light_thin;
    box-shadow: $bs_smallest;
    border-radius: $br_medium;
    right: 0;
    top: 46px;
    padding: 20px;
    box-sizing: border-box;

    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      position: absolute;
      border-left: $border_grey_light_thin;
      border-top: $border_grey_light_thin;
      right: 13px;
      top: -8px;
      background: $color_white;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      h3 {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: $color_grey;
        margin: 0;
        text-transform: uppercase;
      }

      .close-button {
        margin-top: -5px;
        margin-right: -5px;
      }
    }

    .body {
      .form-row {
        text-align: left;
        font-weight: 400;
      }
    }

    .action-area {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
