@import "scssShared/flexbox.scss";
@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

.select-single-value,
.select-option {
  @include flexAcross();
  align-items: center;

  .gravatar-image {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;

    .inicials {
      font-size: 7px;
    }
  }

  .hidden-icon {
    margin-right: 3px;
  }
}

.select-option {
  word-break: break-all;
}

.select-field,
.string-select-wrapper,
.whisperer,
.select-input {
  .caret-down {
    font-size: 11px;
    margin-right: 2px;
    margin-left: -1px;
    color: $color_grey_lighter;
  }
}

.select-field,
.string-select-wrapper,
.whisperer {
  &.error {
    .select-input {
      > div {
        border-color: $color_warning_red;
        animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
        box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
      }
    }
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }

    .select-input {
      > div {
        background: #e6e6e6;
      }
    }
  }
}

.tag_select__control {
  .tag_select__value-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tag_select__indicators {
    .tag_select__indicator {
      padding: 5px;

      .caret-down {
        font-size: 8px;
      }

      &.tag_select__loading-indicator {
        font-size: 2px;
      }
    }

    .tag_select__indicator-separator {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}

.select-field {
  position: relative;

  &.allow-copy {
    .select-copy-icon {
      position: absolute;
      right: 30px;
      top: 28px;

      &.no-label {
        top: 10px;
      }
    }

    .react-select-redux-field__value-container--is-multi {
      padding-right: 32px;
    }
  }

  &.has-clear-button {
    .react-select-redux-field__single-value {
      max-width: calc(100% - 36px);
    }
  }
}
