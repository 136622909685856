@import "scssShared/variables.scss";

.root {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;

  background-color: $color_white;
  border: 1px solid #cccccc;
  border-radius: $br_large;

  .icon {
    color: #222222;
    font-size: 16px;
    line-height: 16px;
  }

  .text {
    margin: 0;
    padding: 0;

    color: #222222;
    font-size: 14px;
    line-height: 17px;

    b,
    strong {
      color: $color_primary;
      font-weight: 400;
    }
  }
}
