@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

@mixin destination-chip-color($color) {
  background: $color;
  color: $color_white;
  border-color: $color;
  opacity: 1;
}

.segment-export {
  .segment-export-table {
    thead {
      th {
        font-size: 11px;
        color: $color_grey;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    tr {
      td {
        .disabled-export-tooltip {
          font-weight: normal;
          width: 220px;
          font-size: 13px;
          line-height: 135%;
          color: $color_white;
          text-transform: none;
          padding: 10px;
          letter-spacing: normal;
          text-align: left;
        }
      }

      &:first-child {
        td {
          padding-top: 5px;
        }
      }
    }
  }

  .ban-icon {
    color: $color_grey;
    opacity: 0.5;
  }

  .status {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.1em;
    font-weight: 700;

    &.finished {
      color: $color_green;
    }

    &.canceled {
      color: $color_warning_red;
    }

    &.waiting {
      color: $color_yellow_waiting;
    }

    &.running {
      color: $color_blue_running;
    }

    &.error {
      color: $color_warning_red;
    }
  }

  .segment-export-history-header {
    margin-top: 20px;
  }

  .running-indicator {
    position: relative;
    cursor: wait;
    padding-left: 37px;

    &:before {
      margin-top: -10px;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 9px;
      top: 50%;
      content: "";
      border-radius: 20px;
      background-clip: padding-box;
      border-color: rgba($color_white, 0.25);
      border-width: 2px;
      border-style: solid;
      border-top-color: $color_white;
      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
      box-sizing: border-box;
    }
  }
}
