@import "scssShared/variables.scss";

.container {
  width: 100%;
  margin-bottom: 10px;

  .paper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;

    > h3 {
      font-size: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color_grey;
      margin: 0 10px;
    }

    .sourcesWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .sources {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        .source {
          margin: 10px 20px 10px 0;
          min-height: 30px;

          .checkbox {
            display: flex;
            align-items: center;

            &:hover {
              opacity: 0.5;
            }

            .sourceIcon {
              width: 32px;
              height: 32px;
              flex-shrink: 0;
              box-sizing: border-box;
              padding: 7px;
              border-radius: 50%;
              transition: $transition_default;
              background: $color_primary;
              margin-right: 8px;

              img {
                width: 18px;
                height: 18px;
              }

              &.disabled {
                background-color: $color_very-light-grey;
              }

              &.checked {
                @each $name, $color in $colors {
                  &.#{$name} {
                    background: $color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
