@import "variables.scss";

.react-datepicker-popper[data-placement^="top"] {
  .calendar-dropdown {
    .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
      &:before {
        bottom: -1px;
        border-top-color: $color_border;
      }
    }
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  .calendar-dropdown {
    .react-datepicker__triangle {
      border-bottom-color: $color_white;
      &:before {
        border-bottom-color: $color_border;
      }
    }
  }
}

.react-datepicker-popper {
  &.bottom-end {
    .react-datepicker__triangle {
      left: auto;
      right: 50px;
    }
  }
}

.react-datepicker-popper,
.static-scheduler-datepicker,
.users-activity-datepicker,
.dynamic-field-datepicker {
  z-index: 2 !important;
  .calendar-dropdown {
    border: $border_grey_light_thin;
    box-shadow: $bs_small;

    .react-datepicker__day {
      color: $color_black;
      font-size: 13px;
      font-weight: bold;
      width: 36px;
      height: 36px;
      line-height: 36px;
      border: solid 2px transparent;
      box-sizing: border-box;

      &:hover {
        border-radius: 0;
        background: $color_grey_secondary_menu;
        color: $color_grey;
      }
    }

    .react-datepicker__day--today {
      border-color: $color_primary;
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background: $color_primary;
      color: $color_white;
      border-radius: 0;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end {
      background: $color_primary;
      color: $color_white;
      border-radius: 0;
      opacity: 1;

      &:hover {
        background: $color_primary;
        color: $color_white;
      }
    }

    .react-datepicker__header {
      background: $color_white;
      border-bottom: 0;

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow {
        height: 5px;
        width: 5px;
        top: 2px;
      }

      .react-datepicker__year-dropdown-container--scroll {
        margin-left: 24px;
      }
    }

    .react-datepicker__current-month {
      display: none;
    }

    .react-datepicker__day-name {
      color: $color_black;
      font-size: 13px;
      font-weight: bold;
      width: 36px;
      height: 36px;
      line-height: 36px;
    }

    .react-datepicker__navigation {
      margin-top: 10px;

      &:focus {
        outline: none;
      }
    }

    .react-datepicker__header__dropdown {
      margin: 10px 0;
    }

    .react-datepicker__month-read-view--selected-month,
    .react-datepicker__year-read-view--selected-year {
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      color: $color_black;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
      top: 20px;
    }

    .react-datepicker__year-dropdown {
      .react-datepicker__navigation {
        margin-top: 0;
        width: 0;
        //height: auto;
      }
    }
  }
}

.static-scheduler-datepicker {
  .calendar-dropdown {
    .react-datepicker__day--disabled {
      cursor: default;
      color: #ccc;
    }
  }
}
