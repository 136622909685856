@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.buttons {
  display: flex;
  gap: 10px;
}

.steps {
  opacity: 0.5;
}

.row {
  padding: 20px;

  border-bottom-left-radius: $br_medium;
  border-bottom-right-radius: $br_medium;
  border-bottom: none;
}

.sendersWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: stretch;

  .label {
    font-size: 10px;
    font-weight: 700;
    color: $color_black;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 10px;
  }
}

.sender {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: $border_grey_light_thin;
  padding: 10px;

  .defaultButton,
  .resendButton {
    font-size: 13px;
    text-transform: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
  }

  .senderEmail {
    flex: 1;
    font-weight: 700;
  }

  .default {
    color: $color_black_lighter;
  }

  .verified {
    color: $color_green;
  }

  .default,
  .verified {
    font-size: 13px;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .resendButton {
    & > span {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}

.addSender {
  display: flex;
  gap: 20px;
  align-items: center;

  .addSenderEmail {
    margin-left: 10px;
    flex: 1;
  }
}

.addSenderButton {
  align-self: flex-start;
  margin-left: 10px;
}
