@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";
@import "scssShared/animations.scss";

.inputAndButtonWrapper {
  display: flex;
  align-items: center;

  .clearImageButton {
    margin-left: 10px;
    flex-shrink: 0;
  }
}

.fileField {
  box-sizing: border-box;
  position: relative;
  width: 100%;

  .label {
    cursor: pointer;

    .labelText {
      @include input-label-styles;

      display: block;
    }

    .hiddenInput {
      display: none;
    }

    .box {
      border: $border_grey_light_thin;
      border-radius: $br_small;
      font-size: 15px;
      line-height: 18px;
      transition: $transition_default;
      background: $color_white;

      box-sizing: border-box;
      flex-grow: 1;
      min-width: 0;
      padding: 4px 5px 4px 15px;
      display: flex;
      justify-content: space-between;

      &:hover {
        border: $border_grey;
      }

      &:focus {
        outline: none;
        border: $border_primary_thin;
        box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
      }

      .fileName {
        margin: auto 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
      }
      .button {
        pointer-events: none;
        flex-shrink: 0;
      }
    }

    .errorMessage {
      @include input-error-message-styles;
    }
  }

  &.disabled {
    .label {
      .box {
        border: $border_grey_light_thin;
        background: #e6e6e6;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  &.hasError {
    .label {
      .box {
        border: $border_warning_red;
        box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25);
        animation: animation-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
  }
}
