.fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
