@import "scssShared/variables.scss";
@import "scssShared/flexbox.scss";

.carousel {
  width: 100%;
  position: relative;

  .carouselContent {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .carouselButton {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
    background: $color_white;
    border: $border_grey_light_thin;
    border-radius: 50%;
    color: $color_border;
    text-align: center;
    font-size: 15px;
    box-shadow: $bs_smallest;

    &:focus {
      border: $border_grey_light_thin;
      outline: none;
    }

    &:hover {
      box-shadow: $bs_large;
    }

    &.carouselLeft {
      left: -15px;
    }

    &.carouselRight {
      right: -15px;
    }
  }

  .eventExample {
    width: 100%;
    padding: 10px 25px;
    border: $border_grey_light_thin;
    background: #fafafa;
    box-sizing: border-box;
    border-radius: $br_small;
    top: 0px;
    transition: left 0.25s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .titleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 6px;

      h4 {
        font-size: 16px;
        word-break: break-word;
        color: $color_grey;
        line-height: 19px;
        margin: 0;
      }

      .showRawPayload {
        white-space: nowrap;
      }
    }

    .payload {
      color: $color_grey;
      font-weight: normal;
      font-size: 12px;

      .rawPayload {
        background-color: $color_catskill_white;
        border: $border_grey_light_thin;
        border-radius: $br_small;
        padding: 8px;
        font-size: 13px;
        font-weight: 400;
        color: $color_black_lighter;
        max-width: 100%;
        box-sizing: border-box;

        pre {
          margin: 0;
          white-space: pre-wrap;
          word-wrap: break-word;
          max-width: 100%;
        }
      }
    }
  }
}
