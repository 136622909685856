@import "scssShared/variables.scss";

.slider {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.disabled {
    .sliderBackgroundFilled {
      background: $color_grey_lighter;
    }
    .sliderHandle {
      cursor: not-allowed;

      &:hover {
        border-color: $color_border;
        color: $color_border;
      }
    }
  }

  &.noBaseSegment .sliderBackgroundFilled {
    border-top-left-radius: $br_medium;
    border-bottom-left-radius: $br_medium;
  }

  .sliderBackground,
  .sliderBackgroundFilled {
    height: 12px;
  }
  .sliderBackground {
    position: relative;
    background-color: $color_border;
    border-radius: 6px;
    margin-top: 15px;
  }
  .sliderBackgroundFilled {
    background: linear-gradient(to right, $color_primary, $color_primary_lighter);
    position: absolute;
    z-index: 1;
  }
  .sliderHandle {
    position: absolute;
    width: 24px;
    height: 24px;
    border: $border_grey_light_thin;
    border-radius: $br_medium;
    background-color: white;
    z-index: 3;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color_border;
    cursor: pointer;
    font-size: 14px;
    transition: color 0.1s ease, border-color 0.1s ease;

    &:hover {
      border-color: $color_grey;
      color: $color_grey;
      box-shadow: $bs_small;
    }
  }
  .baseSegmentMarker {
    position: absolute;
    height: 24px;
    width: 2px;
    z-index: 2;
    background-color: $color_grey;
    top: 50%;
    transform: translateY(-50%);

    .markerLabel {
      position: absolute;
      bottom: calc(100% + 0px);
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      color: $color_grey;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
  }
  .label {
    display: flex;
    align-items: baseline;
    font-weight: 600;
    font-size: 15px;
    gap: 5px;
  }
  .percentage {
    font-size: 12px;
    color: $color_grey;
    font-weight: 400;
  }
}
