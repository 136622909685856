@import "scssShared/variables.scss";

.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;

  background-color: $color_white;
  border: 1px solid $color_very-light-grey;
  box-shadow: $bs_smallest;
  border-radius: $br_large;

  .title {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #999999;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
      padding: 4px 8px 4px 4px;
      gap: 4px;

      background-color: $color_white;
      border: 1px solid #dddddd;
      border-radius: $br_large;

      &.disabled {
        background-color: #f4f4f4;

        img {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }

      .icon {
        width: 12px;
        height: 12px;
        font-size: 12px;
        font-weight: 900;
        color: $color_green;
      }

      img {
        height: 24px;
      }

      .name {
        color: #555555;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.showMore {
  font-size: 12px;
  font-weight: 700;
  height: 30px;
  letter-spacing: normal;
  margin-left: 5px;
  text-transform: none;
}
