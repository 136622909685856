@import "scssShared/variables.scss";

.badge {
  border: 1px solid $color_primary;
  padding: 4px 6px;
  background-color: white;
  color: $color_primary;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: $br_small;
  vertical-align: middle;

  &.sm {
    font-size: 8px;
    padding: 2px 3px;
  }
}
