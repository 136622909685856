@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.container {
  display: flex;
  padding: 10px;
  background-color: $color_catskill-white;
  border-radius: 8px;
  border: 1px solid $color_border;
  transition: all 0.1 ease;

  &.highlighted {
    border-color: rgba(254, 127, 102, 0.5);
    box-shadow: 0 0 4px 2px rgba(254, 127, 102, 0.1);
  }

  &.greyedOut {
    opacity: 0.25;
  }
}

.dragHandle {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_grey;
  margin-right: 5px;
  border-radius: 8px;
  cursor: grabbing;
  transition: all 0.1s ease;
  margin-top: 27px;

  &:hover:not(.disabled) {
    background-color: rgba($color_grey, 0.25);
    color: $color_grey_lighter;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.symbol {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  width: 24px;
  height: 24px;
  margin-top: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_blue_running;
  background-color: rgba($color_blue_running, 0.1);
  border: 1px solid $color_blue_running;
  border-radius: 8px;
  flex: 0 0 24px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tooltipWrapper {
  align-self: flex-start;
  height: 44px;
  margin-top: 18px;
  display: flex;
  align-items: center;

  .info {
    font-size: 14px;
    color: $color_grey_lighter;
  }
}

.select {
  & :global(.react-select-redux-field__menu) {
    z-index: 2;
  }

  &.readOnly {
    & :global(.select-input) > div {
      background-color: $color_catskill-white;
    }

    & :global(.react-select-redux-field__single-value) {
      color: $color_black;
    }
  }
}

.typedCond {
  flex: 1;
  max-width: calc(100% - 50px);

  &.typedCondDnD {
    max-width: calc(100% - 81px);
  }

  & > *:not(:first-child) {
    margin-top: 10px;
  }
}

.simpleCondInputs {
  max-width: calc(100% - 70px);
  &.simpleCondInputsDnD {
    max-width: calc(100% - 101px);
  }
}

.simpleCondInputs,
.typedCondRow {
  display: flex;
  align-items: flex-start;
  margin: 0px 10px;
  flex: 1;
  font-size: 0;

  & > *:not(:first-child) {
    margin-left: 10px;
  }

  .subjectSelect {
    flex: 0 0 260px;
  }

  .operatorSelect {
    flex: 0 0 200px;
  }

  .typeSelect {
    flex: 0 0 185px;
  }

  .attributeLocationField {
    flex: 0 0 430px;
  }

  .fullWidth {
    flex: 1;
  }

  .locationKeyField {
    input {
      border-left: 0;
      border-radius: 0 $br_small $br_small 0;

      &:read-only,
      &:disabled,
      &:focus,
      &:hover {
        border-left: 0;
      }
    }
  }
}

.valueInput:not(.select) {
  input {
    height: 44px;
  }

  &:disabled {
    background-color: $color_catskill-white;
    cursor: not-allowed;
  }

  &.datetime {
    min-width: 210px;
  }
}

.datetime {
  input {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.spacer {
  width: 10px;
}

.label {
  @include input-label-styles;
}

.error {
  @include input-error-message-styles;
}

.attributeLabel {
  display: flex;
  align-items: baseline;
  margin-bottom: 6px;

  & .label {
    margin-bottom: 0;
    margin-right: 5px;
  }

  & .tooltip {
    font-size: 11px;
    color: $color_grey_lighter;
  }
}

.attributeLocation {
  display: flex;
  align-items: flex-start;

  & :global(.react-select-redux-field__control) {
    border-radius: 4px 0 0 4px;
  }

  input {
    border-radius: 0 4px 4px 0;
  }
}
