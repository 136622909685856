.root {
  padding: 20px;
  display: flex;
  align-items: flex-end;
  gap: 20px;

  .selectFieldWrapper {
    flex: 1;
  }
}
