@import "scssShared/variables.scss";

.setup-events {
  .priority-icon,
  .autoload-icon {
    color: $color_green;
    font-size: 20px;
  }

  .actions {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
  }

  .top-bar {
    border-bottom: $border_grey_white_bg;
    padding: 15px 5px 15px 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    font-size: 15px;
    color: $color_grey_lighter;
  }

  .source-icon {
    width: 32px;
    height: 32px;
    border: 1px solid $color_border;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 80%;
      height: auto;
    }
  }

  .source-name {
    font-size: 14px;
    font-weight: bold;
    color: $color_grey_lighter;
    margin-left: 7px;
  }

  .source-cell-content {
    display: flex;
    align-items: center;
  }

  .is-hidden > div {
    opacity: 0.4;
  }
}
