@import "scssShared/variables.scss";

.container {
  padding: 10px;
  background-color: $color_catskill-white;
  border-radius: 8px;
  border: 1px solid $color_border;
  transition: all 0.1 ease;
  display: flex;

  &.negation {
    background-color: #e8eaec;
  }

  &.error {
    border-color: $color_warning_red;
    border-style: dashed;
    background-color: rgba($color_warning_red, 0.05);
  }

  &.highlighted {
    border-color: rgba(254, 127, 102, 0.5);
    box-shadow: 0 0 4px 2px rgba(254, 127, 102, 0.1);
  }

  &.greyedOut {
    opacity: 0.25;
  }
}

.inputsWrapper {
  flex: 1;
  max-width: calc(100% - 34px);
}

.buttonsWrapper {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hiddenAttributeWarning {
  color: darken($color_yellow_waiting, 25%);
  border: 1px solid darken($color_yellow_waiting, 25%);
  background-color: rgba($color_yellow_waiting, 0.1);
  padding: 8px;
  border-radius: $br_medium;
  font-size: 14px;
  margin-bottom: 10px;

  .warningIcon {
    margin-right: 5px;
  }
}

.main {
  display: flex;
  align-items: flex-start;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.verticalAlignmentWrapper {
  height: 34px;
  display: flex;
  align-items: center;
}

.dragHandle {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_grey;
  border-radius: 8px;
  cursor: grabbing;
  transition: all 0.1s ease;
  flex: 0 0 26px;
  margin-right: -5px; // hack

  &:hover:not(.disabled) {
    background-color: rgba($color_grey, 0.25);
    color: $color_grey_lighter;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.symbol {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_blue_running;
  background-color: rgba($color_blue_running, 0.1);
  border: 1px solid $color_blue_running;
  border-radius: 8px;
  flex: 0 0 24px;
  cursor: default;
}

.inputs {
  flex: 1;
  min-width: 0;
}

.topRow {
  display: flex;
  align-items: flex-start;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.attributePicker {
  flex: 1;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 22px;
}

.segmentedWrapper {
  display: flex;
  font-size: 10px;
  font-weight: 700;
  color: $color_grey;
  align-items: flex-end;

  & > *:not(:first-child) {
    margin-left: 4px;
  }

  .segmentedCount {
    color: $color_grey_lighter;
  }

  .segmentedPercentage {
    font-weight: 400;
  }
}

.errorMessage {
  color: $color_warning_red;
  font-size: 13px;
  margin-top: 5px;
}
