@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.batch {
  display: flex;
  flex-direction: column;
  padding: 20px;

  background-color: #f7f9fb;
  border: 1px solid #cccccc;
  border-radius: $br_large;

  .label {
    @include input-label-styles;
    color: #777777;
  }

  .firstRow {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    .segmentPicker {
      width: 100%;
    }

    .segmented {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 5px;

      .titleWrapper {
        display: flex;
        gap: 5px;

        .title {
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }
      }

      .numbersWrapper {
        background: $color_white;
        border: 1px solid #cccccc;
        border-radius: $br_large;
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 10px;
      }
    }
  }

  .secondRow {
    display: flex;
    align-items: center;
    gap: 10px;

    .text {
      font-size: 14px;
      line-height: 17px;
      color: #999999;
    }
  }
}
