@import "scssShared/variables.scss";
@import "scssShared/animations.scss";

@mixin destination-chip-color($color) {
  background: $color;
  color: $color_white;
  border-color: $color;
  opacity: 1;
}

.destination {
  width: 250px;
}

.destinationChip {
  transition: $transition_default;
  background: $color_grey_secondary_menu;
  color: $color_grey;
  border: $border_grey_light_thin;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $br_small;
  position: relative;
  box-sizing: border-box;
  padding-left: 7px;
  padding-right: 7px;

  &:focus {
    outline: none;
  }

  .destinationIcon {
    max-width: 20px;
    max-height: 20px;
    display: block;
    opacity: 1;
    transition: $transition_default;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      @include destination-chip-color($color);
    }
  }

  &.green {
    @include destination-chip-color($color_green);
  }

  &.blue {
    @include destination-chip-color($color_blue);
  }
}

.destinationInfo {
  transition: $transition_default;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
}

.segmentExportSettingsRow {
  border: none !important;

  .destination {
    .destinationChipWrapper {
      display: flex;
      align-items: center;
    }
  }
}

.exportActions {
  display: flex;

  .waiting {
    background: $color_yellow_waiting;
    border-color: $color_yellow_waiting;
    color: $color_white;
  }

  .running {
    background: $color_blue_running;
    border-color: $color_blue_running;
    color: $color_white;
  }

  .cancel {
    margin-left: 5px;
  }
}
