@import "scssShared/variables.scss";

.badge {
  border: $border_grey_light_thin;
  padding: 4px 6px;
  background-color: white;
  color: $color_grey_lighter;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: $br_medium;
  vertical-align: middle;
  display: inline-flex;
  gap: 4px;
}
