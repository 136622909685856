@import "scssShared/variables.scss";
@import "scssShared/animations.scss";
@import "scssShared/mixins.scss";

.wrapper {
  position: relative;
  width: 100%;
}

.spinner {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.idChip {
  color: $color_black_lighter;
  font-size: 12px;
  text-transform: none;
  text-decoration-color: transparent;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  padding: 3px 0;
  background-color: #e6e6e6;
  border-radius: $br_small;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  box-sizing: border-box;
  transition: all 0.1s ease;

  &:hover:not(.disabled) {
    text-decoration-color: inherit;
    background-color: #e0e0e0;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.segments {
  box-sizing: border-box;
  min-height: 44px;
  width: 100%;
  padding: 10px 28px 10px 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  background: $color_white;
  border: 1px solid #cccccc;
  border-radius: $br_large;
  cursor: pointer;
  transition: $transition_default;

  &:focus {
    outline: none;
  }

  &:hover {
    border: $border_grey;
  }

  &.focused {
    border: $border_primary_thin;
    box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
  }

  &.error {
    @include input-error-animation;
  }

  .segment {
    padding: 6px;
    display: flex;
    align-items: center;
    gap: 5px;

    &.lookalike {
      background: $color_yellow;
      padding: 0 6px 0 2.5px;
    }

    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%),
      $color_primary;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: $br_medium;
    color: $color_white;
    font-size: 12px;
    line-height: 100%;

    .closeButton {
      height: 10px;
      width: 7px;
      font-size: 10px;

      svg {
        pointer-events: none;
      }
    }
  }

  .placeholder {
    width: 210px;
    padding: 0;

    background: transparent;
    border: none;
    color: #777777;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.025em;
    line-height: 100%;
    opacity: 0.5;

    &:focus {
      outline: none;
    }

    &.hasValue {
      opacity: 1;
      color: $color_black_lighter;
    }
  }
}

.input {
  @include input-styles;
  @include placeholder {
    opacity: 1;
  }

  box-sizing: border-box;
  cursor: pointer;
  min-height: 44px;
  overflow-y: hidden;
  padding: 4px 28px 4px 14px;
  resize: none;
  width: 100%;

  &.error {
    @include input-error-animation;
  }

  &.open {
    @include placeholder {
      opacity: 0.5;
    }
  }

  &.hasValue {
    padding-left: 72px;
  }

  &:disabled {
    @include placeholder {
      opacity: 0.7;
    }
  }
}

.button {
  box-sizing: border-box;
  position: absolute;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  border: none;
  border-left: $border_grey_light_thin;
  color: $color_grey_lighter;
  background: transparent;
  font-size: 11px;
  padding: 3px 7px;
  outline: none;

  &:hover:not(:disabled) {
    color: $color_grey;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.icon {
  pointer-events: none;
}

.label {
  @include input-label-styles;
}

.lookalikeIconWrapper {
  width: 20px;
  height: 20px;
  background: darken($color_yellow, 20%);
  border-radius: $br_small;
  display: flex;
  justify-content: center;
  align-items: center;
}
