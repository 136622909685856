@import "scssShared/variables.scss";

.root {
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  flex-shrink: 0;
  gap: 2px;

  border: 1px solid #cccccc;
  border-radius: $br_medium;

  &.white {
    background-color: $color_white;
  }

  .icon {
    width: 10px;
    height: 8px;

    color: #777777;
    font-size: 8px;
    line-height: 8px;
  }

  .text {
    color: #777777;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
  }
}
