@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  padding: 20px;
  display: flex;

  &:nth-child(even) {
    background-color: $color_catskill-white;
  }

  &:not(:last-child) {
    border-bottom: $border_grey_light_thin;
  }

  &:first-child {
    border-top-left-radius: $br_small;
    border-top-right-radius: $br_small;
  }

  &:last-child {
    border-bottom-left-radius: $br_small;
    border-bottom-right-radius: $br_small;
  }
}

.description {
  flex: 0 0 240px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 5px;
  }

  p {
    font-size: 12px;
    color: $color_grey_lighter;
    margin: 0;
  }

  a {
    text-transform: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    color: $color_primary;
  }
}

.content {
  flex: 1;
}

.label {
  margin: 0 0 8px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
}

.generalSection {
  @extend .section;

  .content {
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 3fr 2fr;
  }
}

.descriptionSection {
  @extend .section;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .attributeLabelsList {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;

      :global(.tag-picker) {
        margin-right: 0;
      }
    }
  }
}

.dataTypeSection {
  @extend .section;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .topPart {
      display: grid;
      grid-template-columns: 200px 300px;
      gap: 30px;
    }

    .dimensionsList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;

      .dimension {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr min-content;
        gap: 10px;
        align-self: stretch;

        .deleteButton {
          margin-top: 32px;
        }
      }
    }
  }
}

.definitionSection {
  @extend .section;

  .titleWithToggle {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .content {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, 1fr);

    .definitionTypeSwitch {
      grid-area: 1 / 1 / span 1 / span 6;
    }

    .query {
      grid-area: 2 / 1 / span 1 / span 6;
    }

    .sources {
      grid-area: 2 / 1 / span 1 / span 6;
    }

    .types {
      grid-area: 3 / 1 / span 1 / span 6;
    }

    .versions {
      grid-area: 4 / 1 / span 1 / span 3;
    }

    .type {
      grid-area: 4 / 4 / span 1 / span 2;
    }

    .weight {
      grid-area: 4 / 6 / span 1 / span 1;
    }

    .value {
      grid-area: 5 / 1 / span 1 / span 3;
    }

    .outerValue {
      grid-area: 5 / 4 / span 1 / span 3;
    }

    .filter {
      grid-area: 6 / 1 / span 1 / span 3;
    }

    .outerFilter {
      grid-area: 6 / 4 / span 1 / span 3;
    }
  }
}

.iconWrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $color_green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;

  &.error {
    background-color: $color_red;
  }
}

.testSection {
  @extend .section;

  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 0;

    .inputs {
      display: flex;
      gap: 20px;
      align-items: flex-end;

      .testRunButton {
        white-space: nowrap;
      }
    }

    .globalResults {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      font-size: 14px;

      .statusWrapper {
        flex: 1;
        min-width: 0;

        .status {
          display: flex;
          gap: 10px;
          align-items: flex-start;
          border-radius: $br_medium;
          padding: 10px;
          border: 1px solid $color_green;
          background-color: rgba($color_green, 0.1);
          color: $color_green;
          line-height: 1.5;
          word-wrap: break-word;

          .errorsWrapper {
            min-width: 0;
          }

          .iconWrapper {
            flex-shrink: 0;
            margin-top: 2px; // Sorry
          }

          &.error {
            border-color: $color_red;
            background-color: rgba($color_red, 0.1);
            color: $color_red;
          }
        }
      }

      .execTime {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        border-radius: $br_medium;
        padding: 10px;
        border: 1px solid $color_green;
        background-color: rgba($color_green, 0.1);
        color: $color_green;
        line-height: 1.5;

        &.warning {
          border-color: $color_orange;
          background-color: rgba($color_orange, 0.1);
          color: $color_orange;
        }
        &.error {
          border-color: $color_red;
          background-color: rgba($color_red, 0.1);
          color: $color_red;
        }
      }
    }
  }
}

.testResultsSection {
  @extend .section;
}

.emptyMessage {
  display: flex;
  justify-content: center;
  font-size: 13px;
  color: $color_grey_lighter;
  width: 100%;
}

.testResultsTable {
  width: 100%;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.withDimensions {
    .header,
    .subRow {
      grid-template-columns: 180px repeat(3, 1fr) 180px;
    }

    .toggleWrapper {
      grid-column-start: 5;
    }
  }

  .header,
  .subRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 180px;
    align-items: center;
    gap: 20px;
  }

  .header {
    font-weight: 700;
    padding: 0 10px;
  }

  .subRow {
    word-break: break-word;
  }

  .toggleWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color_grey;
    grid-column-start: 4;

    &.filtering {
      color: $color_black_lighter;
    }
  }

  .row {
    background-color: white;
    border: $border_grey_light_thin;
    border-radius: $br_medium;
  }

  .subRow {
    padding: 10px;
    align-items: flex-start;

    &:first-child {
      border-top-left-radius: $br_medium;
      border-top-right-radius: $br_medium;
    }
    &:last-child {
      border-bottom-left-radius: $br_medium;
      border-bottom-right-radius: $br_medium;
    }
    &:not(:first-child) {
      border-top: $border_grey_light_thin;
    }
  }

  .dimensionName {
    font-weight: 700;
  }

  .status {
    display: flex;
    gap: 10px;
    color: $color_red;
    grid-column: span 2;

    .iconWrapper {
      flex-shrink: 0;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    color: $color_grey_lighter;
  }
}
