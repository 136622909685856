@import "scssShared/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: 0 15px 10px;

  border: 1px solid #cccccc;
  border-radius: $br_large;

  .application {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;

    border-bottom: 1px solid #dddddd;

    .name {
      display: flex;
      align-items: center;
      gap: 10px;

      .text {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.addApplication {
  padding: 10px;
  text-align: left;
  text-transform: initial;

  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
}
