@import "scssShared/variables.scss";
@import "scssShared/animations.scss";
@import "scssShared/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.open .button {
    border: $border_primary_thin;
    box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25);
  }

  &.error .button {
    @include input-error-animation;
  }

  &.empty .value {
    opacity: 0.5;
  }
}

.button {
  display: flex;
  position: relative;
  justify-content: flex-start;
  outline: none;
  border: $border_grey_light_thin;
  border-radius: $br_small;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 15px;
  transition: $transition_default;
  background: $color_white;

  &:hover {
    border: $border_grey;
  }
}

.caret {
  box-sizing: border-box;
  position: absolute;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  border: none;
  border-left: $border_grey_light_thin;
  color: $color_grey_lighter;
  background: transparent;
  font-size: 11px;
  padding: 3px 7px;
  outline: none;

  &:hover {
    color: $color_grey;
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 2;
  background-color: white;
  border: $border_grey_light_thin;
  border-radius: $br_small;
  display: flex;
  box-shadow: $bs_small;
}

.roleItem {
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 15px;
  position: relative;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  font-size: 15px;

  &:first-child {
    border-top-left-radius: $br_small;
  }

  &.hovered {
    color: $color_black;
    background-color: rgba($color_primary, 0.1);

    &::after {
      content: " ";
      width: 10px;
      height: 10px;
      border-bottom: $border_grey_light_thin;
      border-left: $border_grey_light_thin;
      background-color: $color_catskill-white;
      transform: rotate(45deg) translateY(-50%);
      position: absolute;
      right: -2px;
      top: calc(50% - 1px);
    }
  }
}

.permissions {
  display: flex;
  gap: 20px;
  background-color: $color_catskill-white;
  padding: 15px;
  border-left: $border_grey_light_thin;
  border-radius: 0 $br_small $br_small 0;
}

.permissionsColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.permissionsSection {
  display: flex;
  gap: 4px;
  flex-direction: column;

  .title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 2px;
  }
}

.destinations {
  margin-bottom: 5px;
}

.permissionItem {
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color_grey;
  gap: 15px;

  &.destination {
    margin-left: 15px;
    font-size: 13px;
  }

  .indicator {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
  }

  &.enabled {
    color: $color_black;

    .indicator {
      background-color: $color_green;
    }
  }
}
