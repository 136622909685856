@import "scssShared/variables.scss";

.setup-sources {
  .top-bar {
    border-bottom: $border_grey_white_bg;
    padding: 15px 5px 15px 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    font-size: 15px;
    color: $color_grey_lighter;
  }

  .actions {
    display: flex;
    gap: 5px;
  }

  .hidden-source > div {
    opacity: 0.4;
  }

  .icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $color_border;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_white;
    box-sizing: border-box;

    img {
      width: 80%;
      height: auto;
    }
  }
}
