@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.label {
  @include input-label-styles;
}

.errorMessage {
  @include input-error-message-styles;
}

.object {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.textInputWrapper,
.selectField {
  width: 100%;
}

.textInputWrapper {
  display: flex;
  align-items: flex-end;
}

.noBorderRight {
  input {
    border-radius: 4px 0 0 4px !important;
    border-right: none !important;
  }
}

.toggleButton {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  min-width: 230px;

  label {
    margin: 0;
  }
}
