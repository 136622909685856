@import "scssShared/variables.scss";
@import "scssShared/mixins.scss";

.steps {
  opacity: 0.5;
}

.row {
  display: flex;
  padding: 20px;
  gap: 20px;

  &:nth-child(even) {
    border-bottom: 1px solid $color_border;

    &:last-child {
      border-bottom-left-radius: $br_medium;
      border-bottom-right-radius: $br_medium;
      border-bottom: none;
    }
  }

  &.provider {
    flex-direction: column;
    gap: 10px;
  }

  .selectFieldWrapper {
    width: 35%;
  }

  .smtpAccount {
    display: flex;
    gap: 10px;

    .server {
      display: flex;
      flex-basis: 40%;

      .serverName {
        flex-basis: 80%;

        input {
          border-radius: 4px 0 0 4px;
        }

        &.error {
          input {
            @include input-error-animation;
          }
        }
      }

      .port {
        flex-basis: 20%;

        input {
          border-left: none;
          border-radius: 0 4px 4px 0;
        }

        &.error {
          input {
            @include input-error-animation;
            border-left: none;
          }
        }
      }
    }

    .credentials {
      flex-basis: 60%;
      display: flex;
      gap: 10px;
    }
  }

  .infobipFields {
    display: flex;
    gap: 10px;
  }

  .description {
    flex-basis: 20%;
    margin: auto 0;

    h3 {
      color: $color_gray_800;
      font-size: 21px;
      letter-spacing: -0.025em;
      line-height: 25px;
      margin: 0;
    }
  }

  .fields {
    flex-basis: 80%;
    display: flex;
    gap: 10px;

    .smtpList {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      width: 100%;

      .smtpItem {
        display: flex;
        align-items: center;
        gap: 20px;

        .logoWrapper {
          width: 64px;
          height: 64px;
          border-radius: $br_large;
          background-color: $color_catskill-white;
          border: $border_grey_light_thin;
          display: flex;
          align-items: center;
          justify-content: center;

          .logo {
            width: 50%;
            height: auto;
          }
        }

        .name {
          font-weight: 700;
        }
      }
    }
  }
}
