@import "scssShared/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .nameWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .name {
      width: 359px;
    }
  }

  .configured {
    box-sizing: border-box;
    padding: 8px 12px;

    color: $color_green;
    border: 1px solid $color_green;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .fileUploadField {
    width: 600px;
  }

  .fileUploadDescription {
    color: $color_grey_lighter;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
  }
}
