@import "scssShared/variables.scss";

.switchRow {
  width: 100%;
  position: relative;
  text-align: center;
  margin-top: 30px;

  hr {
    width: 100%;
    height: 1px;
    background: $color_border;
    display: block;
    position: absolute;
    top: 16px;
    border: 0;
    margin: 0;
  }

  .switchBackground {
    background-color: $color_grey_site_background;
    width: 288px;
    position: relative;
    margin: 0 auto;
  }
}

.customerDetailContent {
  margin-top: 30px;
}
