@import "scssShared/variables.scss";

.section {
  padding: 20px;
  display: flex;
  gap: 30px;

  &:nth-child(even) {
    background-color: $color_catskill-white;
  }

  &:not(:last-child) {
    border-bottom: $border_grey_light_thin;
  }

  &:first-child {
    border-top-left-radius: $br_small;
    border-top-right-radius: $br_small;
  }

  &:last-child {
    border-bottom-left-radius: $br_small;
    border-bottom-right-radius: $br_small;
  }
}

.description {
  flex: 0 0 220px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-size: 20px;
    margin: 0;
    margin-top: 5px;
  }

  p {
    font-size: 12px;
    color: $color_grey_lighter;
    margin: 0;
  }

  a {
    text-transform: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    color: $color_primary;
  }
}

.content {
  flex: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 11px;
  color: $color_black_lighter;
  margin-top: 5px;
}

.attrPickerWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  align-self: stretch;
}

.attributePicker {
  width: 50%;
}

.attributes {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.attribute {
  display: flex;
  gap: 10px;
  align-items: center;

  .name {
    font-weight: 600;
    border: $border_grey_light_thin;
    border-radius: $br_medium;
    padding: 0 15px;
    height: 34px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;

    .icon {
      height: 24px;
      width: 24px;
    }
  }
}
