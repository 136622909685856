.header {
  justify-content: space-between;
}

.modified {
  width: 190px;
}

.action {
  width: 100px;

  .preview {
    margin-right: 5px;
    cursor: default;
  }
}

.tooltip {
  text-align: left; // Table td textAlignRight is overriding default tippy text-align

  :global(.tippy-content) {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 8px;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.tooltipContent {
  font-family: "Monaco", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  white-space: pre;
}
